import React, {createContext, FC, MutableRefObject, useContext, useEffect, useState} from 'react';
import {simpleObs, IObs} from '../../utils/simpleObs';
import ResizeObserver from 'resize-observer-polyfill';

const ctx = createContext<IObs<number>>(null!);

export const useHeight = () => {
  return useContext(ctx);
};

export const HeightProvider: FC<{node: HTMLElement | null}> = ({node, children}) => {
  const [heightObs] = useState(() => simpleObs(0));
  useEffect(() => {
    if (node === null) return;
    const ro = new ResizeObserver(([entry]: ResizeObserverEntry[]) => {
      heightObs.set(entry.contentRect.height);
    });
    ro.observe(node);
    return () => {
      ro.unobserve(node);
      ro.disconnect();
    };
  }, [node, heightObs]);
  return <ctx.Provider value={heightObs}>{children}</ctx.Provider>;
};
