/** @jsx jsx */
import {jsx} from '@emotion/react';
import {useLocale} from '../../hooks/useLocale';
import {IAssignmentScopes, usePermissionScopes} from '../../hooks/usePermissionScopes';
import {AssignmentStatus} from '../../models/AssignmentStatus';
import {startAssignment, stopAssignment} from '../../requests/assignmentCommands';
import {Button} from 'primereact/button';
import {SC} from '../../models/util';
import {cx} from '../../utils/cx';

export const StatusButton: SC<{id: string; status: AssignmentStatus}> = ({id, status, className, style}) => {
  const locale = useLocale();
  const scopes = usePermissionScopes<IAssignmentScopes>(id);
  let props;
  switch (status) {
    case AssignmentStatus.NotStarted:
    case AssignmentStatus.Stopped:
      props = {
        icon: 'mdi mdi-20px mdi-play',
        onClick: () => startAssignment(id),
        style: {color: 'var(--text-color-active-secondary)'},
        disabled: !scopes.has('StartAssignment'),
        tooltip: `${locale.start}`
      };
      break;
    case AssignmentStatus.Started:
      props = {
        icon: 'mdi mdi-20px mdi-pause',
        onClick: () => stopAssignment(id),
        style: {color: 'var(--text-color-active)'},
        disabled: !scopes.has('StopAssignment'),
        tooltip: `${locale.stop}`
      };
      break;
    case AssignmentStatus.Rejected:
      props = {
        icon: 'mdi mdi-20px mdi-restore-alert',
        onClick: () => startAssignment(id),
        style: {color: 'var(--error-color)'},
        disabled: !scopes.has('StartAssignment'),
        tooltip: `${locale.restart}`
      };
      break;
    case AssignmentStatus.Starting:
    case AssignmentStatus.Stopping:
      props = {
        icon: 'mdi mdi-20px mdi-spin mdi-loading',
        style: {cursor: 'wait'},
        disabled: true,
        tooltip: locale.assignmentStatusLocale[status]
      };
  }
  return props === undefined ? null : (
    <Button
      className={cx(['p-button-text', className])}
      icon={props.icon}
      style={{...style, ...props.style}}
      onClick={props.onClick}
      tooltip={props.tooltip}
      tooltipOptions={{position: 'bottom'}}
    />
  );
};
