/** @jsx jsx */
import {jsx, css} from '@emotion/react';
import {SC} from '../../../models/util';

const formatter = new Intl.DateTimeFormat('ru-ru', {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric'
});

export const TimeStamp: SC<{time: number}> = ({time, className}) => {
  const date = new Date(time);
  return (
    <span className={className} css={timeStampCss}>
      {formatter.format(date)}
    </span>
  );
};

// language=SCSS
const timeStampCss = css`
  & {
    color: var(--text-color-secondary);
    font-size: calc(12rem / var(--bfs));
  }
`;
