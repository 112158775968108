/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import {Button} from 'primereact/button';
import {SC} from '../../models/util';
import {cx} from '../../utils/cx';
import {MutableRefObject, useCallback, useEffect, useState} from 'react';
import {throttle} from 'lodash-es';

export const useArrowSwitchState = (total: number, visible: number, step: number = 1) => {
  const max = Math.max(0, Math.min(total - visible));
  const [value, setValue] = useState(0);
  const onPrev = useCallback(() => setValue((prev) => Math.max(prev - step, 0)), [step]);
  const onNext = useCallback(() => setValue((prev) => Math.min(prev + step, max)), [step, max]);
  useEffect(() => {
    if (value < 0) {
      setValue(0);
    } else if (value > max) {
      setValue(max);
    }
  }, [value, max]);
  return {value, onPrev, onNext, setValue, visible, total, step};
};

export const useScroll = (
  ref: MutableRefObject<HTMLElement | null>,
  {onPrev, onNext}: {onPrev: () => void; onNext: () => void},
  delay: number
) => {
  useEffect(() => {
    const list = ref.current;
    if (list === null) {
      return undefined;
    }
    const next = throttle((value: boolean) => {
      if (value) {
        onPrev();
      } else {
        onNext();
      }
    }, delay);
    const onWheel = (e: WheelEvent) => {
      next(e.deltaY > 0);
    };
    list.addEventListener('wheel', onWheel);
    return () => {
      list.removeEventListener('wheel', onWheel);
    };
  }, [ref, onNext, onPrev, delay]);
};

export const ArrowSwitch: SC<{
  state: {
    value: number;
    total: number;
    visible: number;
    onPrev: () => void;
    onNext: () => void;
  };
  vertical?: boolean;
  small?: boolean;
}> = ({className, state: {value, total, onNext, onPrev, visible}, vertical = false, small = false}) => {
  const max = Math.max(0, total - visible);
  const leftLimit = value <= 0;
  const rightLimit = value >= max;
  const hidden = leftLimit && rightLimit;
  return (
    <div className={className} css={[$switch, vertical ? $ver : $hor, hidden && $hidden]}>
      <Button
        disabled={leftLimit}
        className={cx([
          'p-button-rounded p-button-outlined p-button-sm',
          leftLimit ? 'p-button-secondary' : 'p-button-primary'
        ])}
        icon={`mdi mdi-${small ? 18 : 24}px mdi-chevron-left`}
        onClick={onPrev}
        css={[small && $small]}
      />
      <Button
        disabled={rightLimit}
        className={cx([
          'p-button-rounded p-button-outlined p-button-sm',
          rightLimit ? 'p-button-secondary' : 'p-button-primary'
        ])}
        icon={`mdi mdi-${small ? 18 : 24}px mdi-chevron-right`}
        onClick={onNext}
        css={[small && $small]}
      />
    </div>
  );
};

// language=SCSS
const $switch = css`
  & {
    display: flex;
    justify-content: center;
  }
`;

// language=SCSS
const $ver = css`
  & {
    flex-direction: column;
  }
  & > button:first-of-type {
    margin-bottom: calc(10rem / var(--bfs));
  }
`;
// language=SCSS
const $hor = css`
  & {
    flex-direction: row;
  }
  & > button:first-of-type {
    margin-right: calc(10rem / var(--bfs));
  }
`;

// language=SCSS
const $small = css`
  &.p-button.p-button-rounded.p-button-sm {
    height: calc(24rem / var(--bfs));
    width: calc(24rem / var(--bfs));
    padding: 0;
  }
`;

// language=SCSS
const $hidden = css`
  & {
    opacity: 0;
  }
`;
