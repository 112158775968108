import React, {FC, createContext, useEffect, useState, useContext} from 'react';
import {history, Location} from '@netvision/lib-history';
const ctx = createContext<Location>(null!);

export const LocationProvider: FC = ({children}) => {
  const [location, setLocation] = useState(history.location);
  useEffect(() => {
    setLocation(history.location);
    return history.listen((location) => {
      setLocation((prev) => (prev.key === location.key ? prev : location));
    });
  }, []);
  return <ctx.Provider value={location}>{children}</ctx.Provider>;
};

export const useLocation = () => useContext(ctx);
