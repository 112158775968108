import {ISearchFilterValue} from './SearchFilter';
import React, {createContext, FC, useCallback, useContext, useMemo, useRef, useState} from 'react';
import {ISetter, IUpdater} from '../../utils/types';
import {entries, fromEntries} from '../../utils/entries';
import {isEqual} from 'lodash-es';
import {refreshTimeIfToday} from '../../utils/timeUpdater';
import {useWidgetProps} from '../../Root';
import {isArray, isNumber, isString, refine, IShapeValidators, isPropArrayOfTypes} from '../../utils/basicValidators';
import {history, SearchParams, ISerializable} from '@netvision/lib-history';
import {useLocation} from '../../providers/LocationProvider';

export type IFilter = {
  cameras: ISearchFilterValue;
  timestamp: [number, number];
};

const FilterCtx = createContext<IFilter>(null!);

export const useFilter = (): IFilter => useContext(FilterCtx);

const SetFilterCtx = createContext<IUpdater<IFilter>>(null!);

export const useSetFilter = (): IUpdater<IFilter> => useContext(SetFilterCtx);

const FilterDefaultsCtx = createContext<IFilter>(null!);

export const useFilterDefaults = (): IFilter => useContext(FilterDefaultsCtx);

export const FilterProvider: FC = ({children}) => {
  const [validators] = useState(getValidators);
  const {search} = useLocation();
  const {
    props: {defaultPeriodMs}
  } = useWidgetProps();
  const [defaults] = useState(getDefaults(defaultPeriodMs));

  const filter = useMemo(() => {
    const a = SearchParams.parse(search);

    if (isPropArrayOfTypes<number, typeof a, string>(a, 'timestamp', 'number')) {
      const [intervalStart, intervalEnd] = a.timestamp.map((t) => Number(t));
      a.timestamp = refreshTimeIfToday([intervalStart, intervalEnd]);
    }

    return toShape<IFilter>(a, validators, defaults);
  }, [search, validators, defaults]);
  const filterRef = useRef(filter);
  filterRef.current = filter;
  const updateURL = useCallback(
    (filter: IFilter) => {
      const newSearch = SearchParams.stringify(removeDefaults(filter, validators, defaults));
      if (newSearch !== history.location.search)
        history.push({
          search: SearchParams.stringify(removeDefaults(filter, validators, defaults)),
          hash: history.location.hash
        });
    },
    [validators, defaults]
  );
  const setFilter = useCallback(
    (setter: ISetter<IFilter>) => {
      const value = filterRef.current;
      const newValue = setter(value);
      if (!isEqual(value, newValue)) {
        updateURL(setter(filterRef.current));
      }
    },
    [updateURL]
  );
  return (
    <FilterDefaultsCtx.Provider value={defaults}>
      <FilterCtx.Provider value={filter}>
        <SetFilterCtx.Provider value={setFilter}>{children}</SetFilterCtx.Provider>
      </FilterCtx.Provider>
    </FilterDefaultsCtx.Provider>
  );
};

const getDefaults = (statsPeriod: number): IFilter => {
  return {
    cameras: [],
    timestamp: [Date.now() - statsPeriod, Date.now()]
  };
};

const getValidators = (): IShapeValidators<IFilter> => ({
  cameras: isArray(isString),
  timestamp: refine(isArray(isNumber), (v): v is [number, number] => v.length === 2 && v[0] <= v[1])
});

const toShape = <T extends ISerializable>(parsed: {}, validator: IShapeValidators<T>, defaults: T): T => {
  type K = keyof T;
  return entries(parsed).reduce((acc, [key, value]) => {
    if (key in validator && validator[key as K](value) && !isEqual(defaults[key as K], value)) {
      acc[key as K] = value;
    }
    return acc;
  }, Object.assign({}, defaults));
};

const removeDefaults = <T extends ISerializable>(obj: T, validator: IShapeValidators<T>, defaults: T): Partial<T> => {
  return fromEntries(entries(obj).filter(([key, value]) => validator[key](value) && !isEqual(defaults[key], value)));
};
