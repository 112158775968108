/** @jsx jsx */
import {css, jsx, keyframes} from '@emotion/react';
import {SC} from '../../models/util';
import {isString} from 'lodash-es';

export const Card: SC<{title?: string; mode?: keyof typeof modeMap; loading?: boolean; onClick?: () => void}> = ({
  className,
  style,
  children,
  mode,
  loading,
  onClick,
  title
}) => {
  return (
    <div
      title={title}
      onClick={onClick}
      className={className}
      css={[$card, isString(mode) && modeMap[mode], loading && $loading]}
      style={style}>
      {children}
    </div>
  );
};

// language=SCSS
const $card = css`
  & {
    padding: var(--spacer-xs) var(--spacer-sm);
    border-radius: var(--border-radius);
    background: var(--bg-gradient-dark);
    box-shadow: var(--shadow-raised);
  }
`;

// language=SCSS
const $highlighted = css`
  & {
    background: var(--selection-gradient);
    border: solid var(--primary-color) calc(1rem / var(--bfs));
    padding: calc(var(--spacer-sm) - 1rem / var(--bfs));
  }
`;

// language=SCSS
const $selected = css`
  & {
    background: var(--primary-color);
    color: var(--text-color-active);
  }
`;

// language=SCSS
const $error = css`
  & {
    background: var(--input-error-gradient);
    border: solid var(--error-color) calc(1rem / var(--bfs));

    ::after {
    }
  }
`;

const modeMap = {
  highlighted: $highlighted,
  selected: $selected,
  error: $error
};

const pulse = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0.4;
  }
`;

const $loading = css`
  & {
    opacity: 0;
    animation: ${pulse} 600ms ease-in alternate infinite both;
  }
`;
