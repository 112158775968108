import {factoryUseEntityList} from './factoryUseEntityList';
import {createCamerasConnection, IAssignmentType} from '@netvision/lib-api-gateway';
import React, {createContext, FC, useContext, useMemo} from 'react';

const ctx = createContext<Map<number, IAssignmentType>>(null!);
const useFetchTypes = factoryUseEntityList<IAssignmentType>('AssignmentType', createCamerasConnection);
const toMap = <K extends string | number, E extends {id: K}>(arr: Array<E>): Map<K, E> =>
  new Map(arr.map((v) => [v.id, v]));
const useMap = <K extends string | number, E extends {id: K}>(arr: Array<E>): Map<K, E> =>
  useMemo(() => toMap(arr), [arr]);

export const AssignmentTypesProvider: FC = ({children}) => {
  const types = useMap<number, IAssignmentType>(useFetchTypes());
  return <ctx.Provider value={types}>{children}</ctx.Provider>;
};

export const useAssignmentType = (id: number): IAssignmentType | undefined => {
  return useContext(ctx).get(id);
};
