/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import {Card} from '../ui/Card';
import {SC} from '../../models/util';
import {ArrowSwitch, useArrowSwitchState} from '../ui/ArrowSwitch';
import {formatNumber} from '../../utils/formatNumber';
import {useLocale} from '../../hooks/useLocale';
import {useCallback} from 'react';
import {SliderList} from '../ui/SliderList';
import empty from '../../assets/empty.png';

export type IStatEntry = {title?: string; value?: string | number; unit?: string; icon?: string; iconColor?: string};

export const Stats: SC<{loading?: boolean; columns: number; entries: IStatEntry[]; emptyText: string}> = ({
  className,
  loading,
  entries,
  columns,
  emptyText
}) => {
  const switchState = useArrowSwitchState(entries.length, columns);
  const {value: offset} = switchState;
  const render = useCallback(({entry, left, width}) => {
    return <StatEntry key={entry.title || left} entry={entry} style={{left, width}} />;
  }, []);
  const isEmpty = !loading && entries.length === 0;
  return (
    <div className={className} css={$eventStats}>
      {isEmpty ? (
        <EmptyMessage text={emptyText} css={[$padding]} />
      ) : (
        <SliderList<IStatEntry>
          css={[$cardList, $padding]}
          entries={loading ? Array(columns).fill({}) : entries}
          offset={offset}
          gap={'var(--spacer-sm)'}
          render={render}
          columns={columns}
        />
      )}
      <ArrowSwitch className={'p-pt-1 p-pb-3 p-ml-1 p-mr-3'} state={switchState} vertical />
    </div>
  );
};

const $eventStats = css`
  height: calc(150rem / var(--bfs));
  width: 100%;
  display: flex;
`;

const $padding = css`
  padding: var(--spacer-xs) var(--spacer) var(--spacer);
`;

const $cardList = css`
  height: 100%;
  flex-grow: 1;

  mask-image: linear-gradient(
    to left,
    transparent 0%,
    rgba(0, 0, 0, 1) var(--spacer-xs),
    rgba(0, 0, 0, 1) calc(100% - var(--spacer-xs)),
    transparent 100%
  );
`;

const StatEntry: SC<{entry: IStatEntry}> = ({entry: {title, value, unit, icon, iconColor}, className, style}) => {
  const locale = useLocale();

  return (
    <Card
      className={className}
      style={style}
      css={$statEntry}
      loading={value === undefined}
      title={title ? `${title}: ${value ? value.toLocaleString('ru-RU') : ''}` : ''}>
      <h3 css={$statEntry_title}>{title}</h3>
      <div css={$statEntry_icon}>
        <span className={icon} style={{color: iconColor}} />
      </div>
      <div css={$statEntry_value}>
        <span>{value ? formatNumber(locale, value) : value}</span>
        <span>{unit}</span>
      </div>
    </Card>
  );
};

// language=SCSS
const $statEntry = css`
  & {
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
`;

// language=SCSS
const $statEntry_title = css`
  & {
    margin: 0 calc(5rem / var(--bfs)) 0 0;
    font-size: var(--font-size-h3);
    width: calc(100% - calc(38rem / var(--bfs)));
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 400;
  }
`;

// language=SCSS
const $statEntry_value = css`
  & {
    flex: 1 1 0;
    align-self: flex-start;

    > span:nth-child(1) {
      font-size: calc(40rem / var(--bfs));
      font-weight: 700;
      margin-right: calc(10rem / var(--bfs));
    }

    > span:nth-child(2) {
      font-size: calc(16rem / var(--bfs));
      font-weight: 400;
    }
  }
`;

// language=SCSS
const $statEntry_icon = css`
  & {
    font-size: calc(32rem / var(--bfs));
  }
`;

const EmptyMessage: SC<{text: string}> = ({className, style, text}) => {
  return (
    <div className={className} style={style} css={$empty}>
      <div>
        <img src={empty} alt={''} />
        <h4>{text}</h4>
      </div>
    </div>
  );
};

const $empty = css`
  & {
    flex-grow: 1;
    position: relative;
    height: 100%;

    > div {
      border: calc(1rem / var(--bfs)) solid rgba(var(--text-color-secondary-raw), 0.2);
      border-radius: var(--border-radius);
      display: flex;
      height: 100%;
      align-items: center;

      > img {
        display: block;
        height: 100%;
        object-fit: scale-down;
      }

      > h4 {
        line-height: 1em;
        font-size: var(--font-size-h4);
        text-align: center;
        color: var(--text-color);
        font-weight: 400;
        margin: 0;
      }
    }
  }
`;
