import {IObs} from '../utils/simpleObs';
import {useEffect, useState} from 'react';

export const useObs = <T>(obs: IObs<T>) => {
  const [value, setValue] = useState<T>(obs.get);
  useEffect(() => {
    return obs.subscribe(setValue);
  }, [obs]);
  return value;
};
