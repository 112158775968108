import {compose} from './compose';

export interface IObs<T> {
  get(): T;
  subscribe(observer: IConsumer<T>): IDisposer;
  destroy(): void;
}

export interface IWriteableObs<T> extends IObs<T> {
  set(value: T): void;
}

interface IDisposer {
  (): void;
}

interface IConsumer<T> {
  (value: T): void;
}

export const simpleObs = <T,>(initialValue: T): IWriteableObs<T> => {
  let _value = initialValue;
  let _observers: null | Set<IConsumer<T>> = new Set();
  const _notify = () => {
    if (_observers !== null) {
      _observers.forEach((observer) => observer(_value));
    }
  };
  return {
    get: () => _value,
    set: (value) => {
      if (value !== _value) {
        _value = value;
        _notify();
      }
    },
    subscribe: (observer: IConsumer<T>) => {
      if (_observers !== null) {
        _observers.add(observer);
        observer(_value);
      }
      return () => {
        if (_observers !== null) {
          _observers.delete(observer);
        }
      };
    },
    destroy: () => {
      _observers = null;
    }
  };
};

export const mapObs = <A, B>(f: (a: A) => B, obs: IObs<A>): IObs<B> => {
  return {
    get: compose(f, obs.get),
    subscribe: (observer) => obs.subscribe(compose(observer, f)),
    destroy: obs.destroy
  };
};
