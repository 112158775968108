/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import {SimpleVirtualList} from '../ui/SimpleVirtualList';
import React, {FC, useCallback, useEffect, useMemo, useState} from 'react';
import {IAssignmentEvent} from '@netvision/lib-api-gateway';
import {Card} from '../ui/Card';
import {SC} from '../../models/util';
import {useLazyEvents} from '../../hooks/useLazyEvents';
import {once, upperFirst} from 'lodash-es';
import {Status} from '../ui/event/Status';
import {TimeStamp} from '../ui/event/Timestamp';
import {Icon} from '../ui/event/Icon';
import {IAnaEntry} from './anaEntry';
import {useFilter} from '../filters/FilterProvider';
import {EventDetailsAdapter} from '../eventDetails/EventDetailsAdapter';
import {useEventTypes} from '../../providers/EventTypesProvider';
import {useLang} from '../../providers/LangProvider';

export const EventList: SC<{entry: IAnaEntry}> = ({entry, className, style}) => {
  const {cameras, timestamp} = useFilter();
  const [{offset, limit}, setPage] = useState({offset: 0, limit: 10});
  const [activeEvent, setActiveEvent] = useState<IAssignmentEvent | null>();

  const [loading, nextOffset, events, total] = useLazyEvents(offset, limit, {
    assignmentId: entry.assignment.id,
    entity: cameras,
    timestamp
  });

  const onEnd = useMemo(
    () =>
      once((windowSize) => {
        setPage({offset: nextOffset, limit: windowSize});
      }),
    [nextOffset]
  );

  useEffect(() => {
    entry.count.set(total);
  }, [entry, total]);

  const openEventDialog = useCallback((event) => {
    setActiveEvent(event);
  }, []);
  const closeEventDialog = useCallback(() => {
    setActiveEvent(null);
  }, []);

  return (
    <React.Fragment>
      <SimpleVirtualList
        style={style}
        className={className}
        render={({entry, top, height}) =>
          entry === null ? (
            <Placeholder key={'-1'} top={top} height={height} />
          ) : (
            <EventEntry openEventDetails={openEventDialog} key={entry.id} event={entry} top={top} height={height} />
          )
        }
        height={70}
        gap={10}
        entries={loading ? [...events, null] : events}
        onScrollEnd={onEnd}
      />
      {activeEvent && <EventDetailsAdapter event={activeEvent} onHide={closeEventDialog} />}
    </React.Fragment>
  );
};

type EventEntryProps = {event: IAssignmentEvent; top: number; height: number; openEventDetails: <T>(evt: T) => void};
const EventEntry: FC<EventEntryProps> = ({event, top, height, openEventDetails}) => {
  const eventTypes = useEventTypes();
  const eventTypeData = eventTypes.get(event.eventType);
  const lang = useLang();
  const type = upperFirst(eventTypeData?.[`locale${upperFirst(lang)}` as 'localeRu']?.title || '');

  const onClick = useCallback(() => {
    openEventDetails(event);
  }, [event, openEventDetails]);

  return (
    <Card onClick={onClick} className={'p-d-flex p-ai-start'} css={$entry} style={{top, height}}>
      <Icon event={event} size={24} />
      <div css={typeWrapper} data-grow="">
        <div css={$entry_type} title={type}>
          {type}
        </div>
        <TimeStamp time={event.timestamp} />
      </div>
      <Status event={event} />
    </Card>
  );
};

const typeWrapper = css`
  max-width: 66%;
`;

const $entry = css`
  & {
    cursor: pointer;

    position: absolute;
    width: 100%;
  }
  > [data-grow] {
    flex-grow: 1;
  }
  > * {
    margin-right: var(--spacer-sm);
  }
  > *:last-child {
    margin-right: 0;
  }
`;

// language=SCSS
const $entry_type = css`
  & {
    margin-top: calc(-3rem / var(--bfs));
    margin-bottom: calc(5rem / var(--bfs));

    color: var(--text-color);
    font-size: calc(14rem / var(--bfs));
    font-weight: 500;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const Placeholder: FC<{top: number; height: number}> = ({top, height}) => {
  return <Card loading css={$entry} style={{top, height}} />;
};
