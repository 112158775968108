import {createCamerasConnection, IEntity, listEntities} from '@netvision/lib-api-gateway';
import {useMemo} from 'react';
import {ngsiCaseInsensitive} from '../utils/ngsiCaseInsensitive';
import {ngsiSanitize} from '../utils/ngsiSanitize';

export const useEntitySearch = <E extends IEntity>(type: E['type'] | Array<E['type']>) => {
  return useMemo(() => {
    return [
      // by Field
      (query: string, offset: number, limit: number, field: string): Promise<{entities: E[]; finished: boolean}> =>
        load({
          count: true,
          type: join(type, ','),
          orderBy: field,
          attrs: field,
          limit,
          offset,
          q: query.length > 0 ? `${field}~=${ngsiCaseInsensitive(ngsiSanitize(query))}` : undefined
        }),
      // by Id
      (id: string | string[], field: string): Promise<E[]> => {
        id = join(id, ',');
        return id.length > 0
          ? load<E>({id, type: join(type, ','), attrs: field}).then(({entities}) => entities)
          : Promise.resolve([]);
      }
    ] as const;
  }, [type]);
};

const load = <E extends IEntity>(params: {
  type: string;
  id?: string;
  q?: string;
  attrs?: string;
  offset?: number;
  limit?: number;
  orderBy?: string;
  count?: true;
}): Promise<{entities: E[]; finished: boolean}> =>
  listEntities<E>(createCamerasConnection(), {
    ...params
  })
    .then(({results, offset, count}) => {
      return {
        entities: results.every(isObject) ? results : [],
        finished: offset + results.length >= count
      };
    })
    .catch(() => ({
      entities: [],
      finished: true
    }));

const join = (s: string | string[], separator: string) => (Array.isArray(s) ? s.join(separator) : s);

const isObject = <T extends object>(v: unknown): v is T => typeof v === 'object' && v !== null;
