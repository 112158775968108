/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import {Checkbox, CheckboxProps} from 'primereact/checkbox';
import {FC, useState} from 'react';
import {genElementId} from '../../utils/genElementId';

export type ICheckboxFieldProps = CheckboxProps & {label: string};

export const CheckboxField: FC<ICheckboxFieldProps> = ({label, ...rest}) => {
  const [id] = useState(genElementId);
  return (
    <div className={['p-field-checkbox', rest.disabled && 'p-disabled'].filter(Boolean).join(' ')}>
      <Checkbox {...rest} inputId={id} />
      <label htmlFor={id} css={labelCss}>
        {label}
      </label>
    </div>
  );
};

// language=SCSS
const labelCss = css`
  & {
    margin-left: calc(15rem / var(--bfs));
    font-weight: 500;
  }
`;
