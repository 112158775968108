import {useEffect, useState} from 'react';
import {createCamerasConnection, IAssignmentEvent, listEntities} from '@netvision/lib-api-gateway';
import {useSocket} from './useSocket';
import {composeEnum, composeRange, joinQ} from '../utils/ngsiComposeQ';
import {useAsRef} from './useAsRef';
import {AssignmentEventStatus} from '../models/AssignmentStatus';

const empty: [] = [];

const now = () => Date.now();

export const useLazyEvents = (
  offset: number,
  limit: number,
  filters: {
    assignmentId?: string;
    status?: AssignmentEventStatus[];
    entity: Array<string | number>;
    timestamp: [number, number];
  }
) => {
  const [baseTimestamp] = useState(now);
  const {assignmentId, status, entity, timestamp} = filters;
  const q = joinQ([
    assignmentId && `assignmentId==${assignmentId}`,
    status && composeEnum('status')(status),
    composeEnum('assignment.entityId')(entity),
    composeRange('timestamp')(timestamp) || `timestamp<=${baseTimestamp}`
  ]);
  const [total, setTotal] = useState<number | undefined>();
  const [nextOffset, setNextOffset] = useState(0);
  const [events, setEvents] = useState<IAssignmentEvent[]>(empty);
  const [loading, setLoading] = useState(true);
  const limitRef = useAsRef(limit);
  const socketRef = useAsRef(useSocket());
  const filtrationRef = useAsRef(filters);
  useEffect(() => {
    setEvents([]);
    setTotal(undefined);
    setNextOffset(0);
    return socketRef.current.addListener<IAssignmentEvent>('AssignmentEvent', (e) => {
      const {entity, timestamp, assignmentId, status} = filtrationRef.current;
      if (
        timestamp[0] < e.timestamp &&
        e.timestamp < timestamp[1] &&
        (typeof assignmentId === 'string' ? e.assignmentId === assignmentId : true) &&
        (Array.isArray(status) && status.length > 0 ? status.includes(e.status) : true) &&
        (Array.isArray(entity) && entity.length > 0 ? entity.includes(e.assignment?.entityId || '') : true)
      ) {
        setEvents((prev) => [e, ...prev.filter((old) => old.id !== e.id)].sort((a, b) => b.timestamp - a.timestamp));
      }
    });
  }, [q, socketRef, filtrationRef]);
  useEffect(() => {
    let aborted = false;
    setLoading(true);
    listEntities<IAssignmentEvent>(createCamerasConnection(), {
      type: 'AssignmentEvent',
      limit: limitRef.current,
      offset,
      q,
      orderBy: '!timestamp',
      count: true
    })
      .then(({results, count, offset, limit}) => {
        if (!aborted) {
          setNextOffset(Math.min(offset + limit, count));
          setTotal(count);
          setEvents((prev) => [...prev, ...results]);
        }
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        if (!aborted) {
          setLoading(false);
        }
      });
    return () => {
      aborted = true;
    };
  }, [offset, q, limitRef]);
  return [loading, nextOffset, events, total] as const;
};
