/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import {FC, useCallback, useEffect, useMemo, useRef, useState, memo} from 'react';
import {Def, IOption} from '../../utils/types';
import {useMountedRef} from '../../hooks/useMountedRef';
import {isEqual} from 'lodash-es';
import {InputText} from 'primereact/inputtext';
import {SC} from '../../utils/SC';
import {CheckboxField, ICheckboxFieldProps} from '../ui/CheckboxField';
import {cx} from '../../utils/cx';
import {useOverlay} from './useOverlay';
import {IVirtualListChildProps, SimpleVirtualList} from '../ui/SimpleVirtualListV2';
import {E2EModule} from '../../__test__';

export type ISearchFilterValue = Array<string | number>;

export interface ISearchFilter {
  placeholder: string;
  searchPlaceholder: string;
  minLength: number;
  getOptionsByValues: (values: Array<string | number>) => Promise<Array<IOption<string | number>>>;
  searchOptions: (
    query: string,
    offset: number,
    limit: number
  ) => Promise<{options: Array<IOption<string | number>>; finished: boolean}>;
}

const LIMIT = 20;

export const SearchFilter: SC<{
  value: ISearchFilterValue;
  onChange: (newValue: ISearchFilterValue) => void;
  description: ISearchFilter;
}> = ({className, style, value, onChange, description}) => {
  const {searchOptions, placeholder, minLength, getOptionsByValues} = description;

  const [suggestions, setSuggestions] = useState<IOption<string | number>[]>([]);
  const [localValue, setLocalValue] = useState<IOption<string | number>[]>([]);

  const mountedRef = useMountedRef();

  const completeMethod = useMemo(() => {
    return async (params: {query: string; offset: number; limit: number}, signal: AbortSignal): Promise<boolean> => {
      const {query, offset, limit} = params;
      if (query.length < minLength) {
        return true;
      } else {
        const res = await searchOptions(query, offset, limit);
        if (!signal.aborted) {
          if (offset === 0) {
            setSuggestions(res.options);
          } else {
            setSuggestions((prev) => [...prev, ...res.options]);
          }
          return res.finished;
        }
        return true;
      }
    };
  }, [searchOptions, minLength]);

  const localValueList = useMemo(() => localValue.map((v) => v.value), [localValue]);
  const isFirstRef = useRef(true);
  useEffect(() => {
    if (isFirstRef.current) {
      isFirstRef.current = false;
    } else {
      onChange(localValueList);
    }
  }, [localValueList, onChange]);
  useEffect(() => {
    if (!isEqual(localValueList.sort(), value.sort())) {
      if (value.length > 0) {
        getOptionsByValues(value).then((options) => {
          if (mountedRef.current) {
            setLocalValue(options);
          }
        });
      } else {
        setLocalValue([]);
      }
    }
    // eslint-disable-next-line
  }, [value, getOptionsByValues, mountedRef]);
  const options = useMemo(() => {
    const set = new Set(localValueList);
    return [...localValue, ...suggestions.filter((s) => !set.has(s.value))];
  }, [localValueList, localValue, suggestions]);
  const onListChange = useCallback(
    (newValue: Array<string | number>) => {
      const set = new Set(newValue);
      setLocalValue(options.filter((o) => set.has(o.value)));
    },
    [options]
  );
  const values = localValue.map((x) => x.label).join(', ') || placeholder;
  const [ref, visible, setVisible] = useOverlay();
  useEffect(() => {
    if (!visible) {
      setSuggestions([]);
    }
  }, [visible]);
  const onClear = useCallback(() => {
    setLocalValue([]);
    setVisible(false);
  }, [setVisible]);
  return (
    <div
      ref={ref}
      data-cy={E2EModule.attributes.camerasFilter}
      className={cx(['p-fluid', className])}
      css={$main}
      style={style}>
      <span className={'p-input-raised p-input-icon-left p-input-icon-right'}>
        <i className={'mdi mdi-18px mdi-map-marker-radius'} css={$off} />
        <InputText
          onClick={() => setVisible((prev) => !prev)}
          title={values}
          className={'p-highlighted'}
          readOnly
          value={values}
        />
        {localValue.length > 0 ? (
          <i role={'button'} onClick={onClear} className={'mdi mdi-18px mdi-close'} css={$pointer} />
        ) : (
          <i className={'mdi mdi-18px mdi-menu-down'} css={$off} />
        )}
      </span>
      {visible && (
        <SuggestionList
          className={'p-raised p-input-filled'}
          locale={{search: description.searchPlaceholder}}
          value={localValue.map((x) => x.value)}
          onChange={onListChange}
          options={options}
          completeMethod={completeMethod}
          css={$listPosition}
        />
      )}
    </div>
  );
};

const $main = css`
  & {
    position: relative;
    overflow: visible;
  }
`;
const $listPosition = css`
  & {
    top: calc(100% + var(--spacer-xs));
    left: 0;
    position: absolute;
    z-index: 1000;
  }
`;

const $pointer = css`
  & {
    cursor: pointer;
  }
`;

const $off = css`
  & {
    pointer-events: none;
  }
`;

export const SuggestionList: SC<{
  locale: {
    search: string;
  };
  value: Array<string | number>;
  onChange: (newValue: Array<string | number>) => void;
  options: IOption<string | number>[];
  completeMethod: (params: {query: string; offset: number; limit: number}, signal: AbortSignal) => Promise<boolean>;
}> = ({className, style, locale, value, onChange, options, completeMethod}) => {
  const valueAsSet = useMemo(() => new Set(value), [value]);
  const optionsMap = useMemo(() => new Map(options.map((opt) => [opt.value, opt])), [options]);
  const valueAsSetRef = useRef(valueAsSet);
  valueAsSetRef.current = valueAsSet;
  const onChangeRef = useRef(onChange);
  onChangeRef.current = onChange;
  const onItemChange = useCallback<Def<ICheckboxFieldProps['onChange']>>((e) => {
    const set = new Set(valueAsSetRef.current);
    let value;
    const name = e.target.name;
    if (name.startsWith('n')) {
      value = Number(name.slice(1));
    } else {
      value = name.slice(1);
    }
    if (e.checked) {
      set.add(value);
    } else {
      set.delete(value);
    }
    onChangeRef.current(Array.from(set));
  }, []);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState('');
  const [offset, setOffset] = useState(0);
  const [finished, setFinished] = useState(false);
  const onScrollEnd = () => {
    if (!finished && !loading) {
      setOffset(offset + LIMIT);
    }
  };
  useEffect(() => {
    const timeout = setTimeout(() => {
      setQuery(search);
      setOffset(0);
    }, 500);
    return () => {
      clearTimeout(timeout);
    };
  }, [search]);
  useEffect(() => {
    setLoading(true);
    const abr = new AbortController();
    completeMethod({query, offset, limit: LIMIT}, abr.signal).then((res) => {
      if (!abr.signal.aborted) {
        setLoading(false);
        setFinished(res);
      }
    });
    return () => {
      abr.abort();
    };
  }, [completeMethod, query, offset]);
  const onClear = useCallback(() => {
    setQuery('');
    setOffset(0);
    setSearch('');
  }, []);
  const empty = !loading && search.length > 0 && options.length - value.length === 0;
  const optionsRest = useMemo(() => {
    return options.filter((opt) => !valueAsSet.has(opt.value));
  }, [valueAsSet, options]);
  const optionsRestContext: VLContext = useMemo(() => {
    return {
      onItemChange,
      checked: false
    };
  }, [onItemChange]);
  return (
    <div css={$suggestionList} className={className} style={style}>
      <div className={cx(['p-input-icon-left', (loading || search.length > 0) && ' p-input-icon-right'])}>
        <i className={'pi pi-search'} css={$off} />
        <InputText
          autoFocus
          className={cx([empty && 'p-error'])}
          placeholder={locale.search}
          value={search}
          onChange={(e: any) => {
            setSearch(e.target.value);
          }}
        />
        {loading && <i className={'mdi mdi-18px mdi-spin mdi-loading'} css={$off} />}
        {search.length > 0 && !loading && (
          <i role={'button'} onClick={onClear} className={'mdi mdi-18px mdi-close'} css={$pointer} />
        )}
      </div>
      {value.length > 0 && (
        <div>
          <ul>
            {value.map((v) => {
              const opt = optionsMap.get(v);
              if (!opt) {
                return null;
              }
              return (
                <li key={opt.value}>
                  <CheckboxField
                    label={opt.label}
                    name={typeof opt.value === 'number' ? `n${opt.value}` : `s${opt.value}`}
                    checked={true}
                    onChange={onItemChange}
                  />
                </li>
              );
            })}
          </ul>
        </div>
      )}
      {optionsRest.length > 0 && (
        <SimpleVirtualList<IOption<string | number>, VLContext>
          height={30}
          gap={10}
          items={optionsRest}
          keyProp={'value'}
          context={optionsRestContext}
          Child={SuggestionItem}
          ListEl={'ul'}
          onScrollEnd={onScrollEnd}
        />
      )}
    </div>
  );
};

type VLContext = {
  onItemChange: Def<ICheckboxFieldProps['onChange']>;
  checked: boolean;
};

const SuggestionItem: FC<IVirtualListChildProps<IOption<string | number>, VLContext>> = memo(
  ({item: {label, value}, top, height, context: {onItemChange, checked}}) => {
    return (
      <li style={{top, height, position: 'absolute'}}>
        <CheckboxField
          label={label}
          name={typeof value === 'number' ? `n${value}` : `s${value}`}
          checked={checked}
          onChange={onItemChange}
        />
      </li>
    );
  }
);

// language=SCSS
const $suggestionList = css`
  & {
    overflow: hidden;
    width: calc(360rem / var(--bfs));
    border-radius: var(--border-radius);
    background: var(--surface-b);
    padding: var(--spacer-sm);
    padding-top: 0;

    > div:first-of-type {
      position: relative;
      color: var(--text-color-secondary);
      padding: 0;
      border-top: none;
      margin-top: var(--spacer-sm);
    }
    > div {
      overflow: auto;
      max-height: calc(171rem / var(--bfs));
      margin-top: var(--spacer-sm);
      border-top: calc(1rem / var(--bfs)) solid var(--secondary-color);
      padding-top: var(--spacer-sm);
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      font-weight: 500;
      color: var(--text-color-secondary);

      > li {
        padding: 0;
        margin: 0 0 calc(10rem / var(--bfs)) 0;

        label {
          height: calc(30rem / var(--bfs));
          display: flex;
          align-items: center;
        }
      }
      > li:last-child {
        margin: 0;
      }
    }
  }
`;
