type Func<T extends any[], R> = (...a: T) => R;

export function compose(): <R>(a: R) => R;

export function compose<F extends Function>(f: F): F;

/* two functions */
export function compose<A, T extends any[], R>(f1: (a: A) => R, f2: Func<T, A>): Func<T, R>;

/* three functions */
export function compose<A, B, T extends any[], R>(f1: (b: B) => R, f2: (a: A) => B, f3: Func<T, A>): Func<T, R>;

/* four functions */
export function compose<A, B, C, T extends any[], R>(
  f1: (c: C) => R,
  f2: (b: B) => C,
  f3: (a: A) => B,
  f4: Func<T, A>
): Func<T, R>;

/* rest */
export function compose<R>(f1: (a: any) => R, ...func: Function[]): (...args: any[]) => R;

export function compose<R>(...func: Function[]): (...args: any[]) => R;

export function compose(...f: Function[]) {
  if (f.length === 0) {
    // infer the argument type so it is usable in inference down the line
    return <T>(arg: T) => arg;
  }

  if (f.length === 1) {
    return f[0];
  }

  return f.reduce((a, b) => (...args: any) => a(b(...args)));
}
