import React, {FC, Fragment, useContext, useLayoutEffect, useMemo, useRef} from 'react';
// @ts-ignore
import {SingleSpaContext} from 'single-spa-react';
import {Node, WidgetReceivedProps} from '@netvision/lib-widget-renderer';

export const ExtraButtonsAdapter = () => {
  const {areas} = useContext(SingleSpaContext) as WidgetReceivedProps<any, any>;
  const nodes = useMemo(
    () =>
      (areas ?? []).reduce((acc, area) => {
        if (area.name === 'extra-button') {
          acc.push(...area.children);
        }
        return acc;
      }, [] as Node[]),
    [areas]
  );
  if (nodes.length === 0) {
    return null;
  } else {
    return (
      <Fragment>
        {nodes.map((node, index) => (
          <ExtraButton key={index} node={node} />
        ))}
      </Fragment>
    );
  }
};

const ExtraButton: FC<{node: Node}> = ({node}) => {
  const {mountChildren} = useContext(SingleSpaContext) as WidgetReceivedProps<any, any>;
  const divRef = useRef<HTMLDivElement | null>(null);
  useLayoutEffect(() => {
    const div = divRef.current;
    if (!div) return;
    let child = node;
    if (child.type === 'widget') {
      child = {
        ...child,
        props: {
          ...child.props
        }
      };
    }
    return mountChildren(div, [child]);
  }, [mountChildren, node]);
  return <div ref={divRef} style={{marginRight: 'calc(30rem / var(--bfs))'}} />;
};
