/** @jsx jsx */
import {jsx} from '@emotion/react';
import {FC} from 'react';
import {IAssignmentEvent} from '@netvision/lib-api-gateway';
import {Success} from '../icons/Success';
import {Attention} from '../icons/Attention';
import {Drill} from '../icons/Drill';
import {AssignmentEventStatus} from '../../../models/AssignmentStatus';

export const Status: FC<{event: IAssignmentEvent | {status: AssignmentEventStatus}}> = ({event}) => {
  const {status} = event;
  switch (status) {
    case AssignmentEventStatus.FalsePositive:
      return <Success />;
    case AssignmentEventStatus.Handled:
      return <Attention />;
    case AssignmentEventStatus.ViolationDetected:
      return <Drill />;
    default:
      return null;
  }
};
