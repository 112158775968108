/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import {SimpleVirtualList} from '../ui/SimpleVirtualList';
import {useLazyEvents} from '../../hooks/useLazyEvents';
import {IAssignmentEvent} from '@netvision/lib-api-gateway';
import {SC} from '../../models/util';
import {Card} from '../ui/Card';
import {Status} from '../ui/event/Status';
import {TimeStamp} from '../ui/event/Timestamp';
import {FC, Fragment, useCallback, useMemo, useState} from 'react';
import {Title} from '../ui/Title';
import {useLocale} from '../../hooks/useLocale';
import {Preview} from '../ui/event/Preview';
import {once, upperFirst} from 'lodash-es';
import {useFilter} from '../filters/FilterProvider';
import {isNumber} from '../../utils/basicValidators';
import empty from '../../assets/empty.png';
import {EventDetailsAdapter} from '../eventDetails/EventDetailsAdapter';
import {useEventTypes} from '../../providers/EventTypesProvider';
import {useLang} from '../../providers/LangProvider';
import {AssignmentEventStatus} from '../../models/AssignmentStatus';

export const Incidents: FC<{compact?: boolean}> = ({compact = false}) => {
  const locale = useLocale();
  const {cameras, timestamp} = useFilter();
  const [{offset, limit}, setPage] = useState({offset: 0, limit: 10});
  const [loading, nextOffset, events, total] = useLazyEvents(offset, limit, {
    status: [AssignmentEventStatus.ViolationDetected],
    entity: cameras,
    timestamp
  });
  const onEnd = useMemo(
    () =>
      once((windowSize) => {
        setPage({offset: nextOffset, limit: windowSize});
      }),
    [nextOffset]
  );
  const showPlaceholder = loading && events.length === 0;
  const showEmpty = !loading && events.length === 0;
  return (
    <Fragment>
      <Title className={'p-ml-3 p-mb-3'}>
        {locale.incidentsTitle}
        {isNumber(total) && ` - ${total}`}
      </Title>
      <div css={$listContainer}>
        {showEmpty ? (
          <div>
            <EmptyMessage />
          </div>
        ) : (
          <SimpleVirtualList
            entries={showPlaceholder ? Array(5).fill({}) : events}
            render={
              showPlaceholder
                ? ({top, height, index}) => <Placeholder key={index} top={top} height={height} />
                : ({entry, top, height}) => <IncidentEntry key={entry.id} event={entry} top={top} height={height} />
            }
            height={135}
            gap={10}
            onScrollEnd={onEnd}
          />
        )}
      </div>
    </Fragment>
  );
};

const $listContainer = css`
  & {
    position: relative;
    flex-grow: 1;
  }
  > div {
    position: absolute;
    height: calc(100% + var(--spacer-xs));
    margin-top: calc(-1 * var(--spacer-xs));
    padding: var(--spacer-xs) var(--spacer-sm) calc(3 * var(--spacer)) var(--spacer);
    scrollbar-width: none;
    &::-webkit-scrollbar {
      width: 0;
    }
    mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) calc(100% - 4 * var(--spacer)), rgba(0, 0, 0, 0) 100%);
  }
`;

const IncidentEntry: SC<{event: IAssignmentEvent; top: number; height: number}> = ({event, top, height}) => {
  const eventTypes = useEventTypes();
  const eventTypeData = eventTypes.get(event.eventType);
  const lang = useLang();
  const type = upperFirst(eventTypeData?.[`locale${upperFirst(lang)}` as 'localeRu']?.title || '');
  const fallback = <FallbackImg />;
  const [showDialog, setShowDialog] = useState(false);
  const onClick = useCallback(() => {
    setShowDialog(true);
  }, []);
  const onHide = useCallback(() => {
    setShowDialog(false);
  }, []);
  return (
    <div onClick={onClick} className={'p-raised'} css={$entry} style={{top, height}}>
      <div css={$preview}>
        <Preview fallback={fallback} key={event.id} event={event} />
      </div>
      <Card css={$card}>
        <div css={$main}>
          <h4 title={type}>{type}</h4>
          <span>{event.assignment?.entityTitle}</span>
          <TimeStamp time={event.timestamp} />
        </div>
        <div>
          <Status event={event} />
        </div>
      </Card>
      {showDialog && <EventDetailsAdapter event={event} onHide={onHide} />}
    </div>
  );
};

const $entry = css`
  & {
    cursor: pointer;

    position: absolute;
    display: flex;
    width: 100%;
    border-radius: var(--border-radius);
  }
`;

const $preview = css`
  & {
    height: 100%;
    width: calc(240rem / var(--bfs));
    background: var(--surface-a);
    flex-grow: 1;
    border-radius: var(--border-radius);
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
`;

const $card = css`
  & {
    height: 100%;
    display: flex;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    width: calc(100% - 200rem / var(--bfs));
    min-width: calc(200rem / var(--bfs));
    box-shadow: none;
  }
`;

const $main = css`
  & {
    flex-grow: 1;
    margin-right: var(--spacer-xs);
    max-width: 90%;

    display: flex;
    flex-direction: column;

    > h4 {
      margin: 0;
      font-weight: 500;
      color: var(--text-color);
      font-size: calc(16rem / var(--bfs));
      line-height: calc(20rem / var(--bfs));
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      margin-bottom: var(--spacer-xs);
    }
    > span:nth-child(2) {
      margin: 0;
      font-weight: 400;
      color: var(--text-color-secondary);
      font-size: calc(14rem / var(--bfs));
      flex-grow: 1;
    }
    > span:nth-child(3) {
      font-size: calc(12rem / var(--bfs));
    }
  }
`;

const Placeholder: FC<{top: number; height: number}> = ({top, height}) => {
  return <Card loading css={$entry} style={{top, height}} />;
};

const EmptyMessage: SC = ({className, style}) => {
  const locale = useLocale();
  return (
    <div className={className} style={style} css={$empty}>
      <img src={empty} />
      <h4>{locale.incidentsTitleEmpty}</h4>
    </div>
  );
};

const $empty = css`
  & {
    position: relative;
    border: calc(1rem / var(--bfs)) solid rgba(var(--text-color-secondary-raw), 0.2);
    border-radius: var(--border-radius);

    > img {
      display: block;
      width: 100%;
      height: calc(300rem / var(--bfs));
      max-height: calc(100% - var(--font-size-h4) - var(--spacer));
      object-fit: scale-down;
    }
    > h4 {
      margin: calc(-1 * var(--spacer)) 0 0 0;
      padding: 0 var(--spacer) var(--spacer);
      line-height: 1em;
      font-size: var(--font-size-h4);
      text-align: center;
      color: var(--text-color);
      font-weight: 400;
    }
  }
`;

const FallbackImg = () => {
  return <img src={empty} css={$fallbackImg} alt={'image not found'} />;
};

const $fallbackImg = css`
  & {
    width: 100%;
    height: 100%;
    object-fit: scale-down;
    filter: hue-rotate(130deg);
  }
`;
