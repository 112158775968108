import {getPermissionsByIdsMap} from '@netvision/lib-api-gateway';
import React, {createContext, FC, useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {createBatchQueue} from '../utils/batchQueue';

export type IAssignmentScopes = 'StartAssignment' | 'StopAssignment' | 'UpdateAssignment' | 'DeleteAssignment';

export const usePermissionScopes = <K extends string>(id: string): Set<K> => {
  const getScopes = useContext(AssignmentPermissionCtx);
  const [scopes, setRes] = useState<Set<K>>(aSet);
  useEffect(() => {
    getScopes(id)
      .then(setRes)
      .catch(() => setRes(aSet));
  }, [id, getScopes]);
  return scopes;
};

export const AssignmentPermissionsProvider: FC = ({children}) => {
  const [cache] = useState(() => new Map<string, Set<string>>());
  const batchQ = useMemo(
    () =>
      createBatchQueue<string, Set<string>>(17, (inputs) => {
        return getPermissionsByIdsMap(clear(inputs.map((v) => v.args))).then((map) => {
          return inputs.map(({args, $id}) => {
            return {
              $id,
              result: new Set(map.get(args))
            };
          });
        });
      }),
    []
  );
  const getScopes = useCallback(
    (id: string) => {
      const scopes = cache.get(id);
      if (scopes) {
        return Promise.resolve(scopes);
      } else {
        return new Promise<Set<string>>((resolve) =>
          batchQ.add(
            id,
            () => false,
            (scopes) => {
              cache.set(id, scopes);
              resolve(scopes);
            }
          )
        );
      }
    },
    [batchQ, cache]
  );
  return <AssignmentPermissionCtx.Provider value={getScopes}>{children}</AssignmentPermissionCtx.Provider>;
};

type Ctx<K> = (id: string) => Promise<Set<K>>;
const AssignmentPermissionCtx = createContext<Ctx<any>>(null!);

const aSet = <T,>() => new Set<T>();
const clear = (arr: string[]) => Array.from(new Set(arr));
