/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import {SearchFilter} from './SearchFilter';
import {useFilter, useSetFilter} from './FilterProvider';
import {useCallback, useMemo} from 'react';
import {useEntitySearch} from '../../hooks/useEntitySearch';
import {isString} from '../../utils/basicValidators';
import {useLocale} from '../../providers/LocaleProvider';
import {TimeFilter} from './TimeFilter';
import {ICamera} from '../../models/ICamera';

export const Filters = () => {
  const locale = useLocale();
  const {cameras, timestamp} = useFilter();
  const setFilter = useSetFilter();
  const [camerasByField, camerasById] = useEntitySearch<ICamera>('Camera');
  return (
    <div css={$main} className="p-mr-1">
      <SearchFilter
        css={$filter}
        value={cameras}
        onChange={useCallback(
          (newValue) => {
            setFilter((prev) => {
              return {...prev, cameras: newValue};
            });
          },
          [setFilter]
        )}
        description={useMemo(
          () => ({
            placeholder: locale.allCameras,
            searchPlaceholder: locale.search,
            minLength: 0,
            getOptionsByValues: (values) => camerasById(values.filter(isString), 'title').then(cameraOptions),
            searchOptions: (query, offset, limit) =>
              camerasByField(query, offset, limit, 'title').then(({entities, finished}) => {
                return {
                  options: cameraOptions(entities),
                  finished
                };
              })
          }),
          [locale, camerasById, camerasByField]
        )}
      />
      <TimeFilter
        css={$filter}
        anyString={locale.anyTime}
        value={timestamp}
        onChange={useCallback(
          (newValue) => {
            setFilter((prev) => {
              return {...prev, timestamp: newValue};
            });
          },
          [setFilter]
        )}
      />
    </div>
  );
};

const $main = css`
  & {
    width: max-content;
    display: flex;
  }
  & > * {
    margin-right: var(--spacer-xs);
  }
  & > *:last-child {
    margin-right: 0;
  }
`;

const $filter = css`
  & {
    width: calc(210rem / var(--bfs));
  }
`;

const cameraOptions = (arr: ICamera[]) => arr.map(cameraOption);
const cameraOption = ({id, title}: ICamera) => ({label: title, value: id});
