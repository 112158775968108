/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import {FC} from 'react';
import {Incidents} from './incidents/Incidents';
import {E2EModule} from '../__test__';

export const Left: FC = () => {
  return (
    <section data-cy={E2EModule.attributes.allAccidents} css={$left}>
      <Incidents />
    </section>
  );
};
// language=SCSS
const $left = css`
  & {
    grid-column: 1/1;
    grid-row: 2/2;

    display: flex;
    flex-direction: column;
  }
`;
