import {useEffect, useRef, useState} from 'react';

export const useOverlay = () => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    if (visible) {
      const node = ref.current!;
      let pass = true;
      const onInside = () => {
        pass = true;
      };
      node.addEventListener('click', onInside);
      const onOutside = () => {
        if (pass) {
          pass = false;
        } else {
          setVisible(false);
        }
      };
      document.body.addEventListener('click', onOutside);
      return () => {
        node.removeEventListener('click', onInside);
        document.body.removeEventListener('click', onOutside);
      };
    }
    return undefined;
  }, [visible]);
  return [ref, visible, setVisible] as const;
};
