import {ngsiSanitize} from './ngsiSanitize';

export const joinQ = (arr: unknown[]): string | undefined => {
  const res = arr.filter((v) => typeof v === 'string' && v.length > 0);
  return res.length > 0 ? res.join(';') : undefined;
};

export const composeEnum: IComposer<Array<string | number>> = (field) => (value) => {
  if (value.length === 0) {
    return undefined;
  } else {
    return `${field}==${value
      .map((v) => {
        if (isString(v)) {
          return `'${ngsiSanitize(v)}'`;
        } else if (isValidNumber(v) && isNotInfinity(v)) {
          return v.toString();
        }
        return undefined;
      })
      .filter(isString)
      .join(',')}`;
  }
};

export const composeRange: IComposer<number[]> = (field) => (arr) => {
  if (arr.length === 0) {
    return undefined;
  } else if (arr.length === 1) {
    const [value] = arr;
    if (isValidNumber(value) && isNotInfinity(value)) {
      return `${field}==${value}`;
    } else if (isNaN(value)) {
      return `${field}==null`;
    }
    return undefined;
  } else if (isValidNumber(arr[0]) && isValidNumber(arr[1])) {
    const [min, max] = arr.slice().sort();
    const minIsInfinity = min === -Infinity || min === -Number.MAX_SAFE_INTEGER;
    const maxIsInfinity = max === Infinity || min === Number.MAX_SAFE_INTEGER;
    if (minIsInfinity && maxIsInfinity) {
      return undefined;
    } else if (minIsInfinity) {
      return `${field}<=${max}`;
    } else if (maxIsInfinity) {
      return `${field}>=${min}`;
    } else {
      return `${field}==${min}..${max}`;
    }
  } else if (isNaN(arr[0]) && isNaN(arr[1])) {
    return `${field}==null`;
  }
  return undefined;
};

type IComposer<T> = (field: string) => (value: T) => string | undefined;
const isString = (v: unknown): v is string => typeof v === 'string';
const isValidNumber = (v: unknown): v is number => typeof v === 'number' && !isNaN(v);
const isNotInfinity = (v: number): v is number => {
  const abs = Math.abs(v);
  return abs !== Infinity && abs !== Number.MAX_SAFE_INTEGER;
};
