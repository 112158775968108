import React, {createContext, FC, useContext} from 'react';
import {LocaleProvider} from './providers/LocaleProvider';
import {Main} from './components/Main';
import {EntityFetchProvider} from './hooks/useEntity';
import {SocketProvider} from './hooks/useSocket';
import {NotificationSocketProvider} from './hooks/useNotificationSocket';
import {LangProvider} from './providers/LangProvider';
import {EventTypesProvider} from './providers/EventTypesProvider';
import {IWidgetProps} from './IWidgetProps';
import {LocationProvider} from './providers/LocationProvider';

const WidgetPropsContext = createContext<IWidgetProps>(null!);

export function useWidgetProps() {
  return useContext(WidgetPropsContext);
}

const Root: FC<IWidgetProps> = (widgetProps) => {
  return (
    <WidgetPropsContext.Provider
      value={{
        ...widgetProps,
        props: {
          ...(widgetProps.props || {}),
          defaultPeriodMs: widgetProps.props?.defaultPeriodMs || 6.048e8
        }
      }}>
      <SocketProvider>
        <NotificationSocketProvider>
          <LocationProvider>
            <LangProvider>
              <LocaleProvider>
                <EntityFetchProvider>
                  <EventTypesProvider>
                    <Main />
                  </EventTypesProvider>
                </EntityFetchProvider>
              </LocaleProvider>
            </LangProvider>
          </LocationProvider>
        </NotificationSocketProvider>
      </SocketProvider>
    </WidgetPropsContext.Provider>
  );
};

export default Root;
