/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import {FC, useEffect, useMemo, useState} from 'react';
import {ArrowSwitch, useArrowSwitchState} from '../ui/ArrowSwitch';
import {useLocale} from '../../hooks/useLocale';
import {useAllAssignments} from '../../hooks/useAllAssignments';
import {Card} from '../ui/Card';
import {SC} from '../../models/util';
import {IAssignment, ICamera} from '@netvision/lib-api-gateway';
import {useEntity} from '../../hooks/useEntity';
import {SliderList} from '../ui/SliderList';
import {AssignmentTypesProvider, useAssignmentType} from '../../hooks/useAssignmentTypes';
import {isNumber, upperFirst} from 'lodash-es';
import {AssignmentStatus} from '../../models/AssignmentStatus';
import {StatusButton} from './StatusButton';
import {AssignmentPermissionsProvider} from '../../hooks/usePermissionScopes';
import {Counter} from './Counter';
import {Title} from '../ui/Title';
import {EventList} from './EventList';
import {HeightProvider, useHeight} from './HeightProvider';
import {useObs} from '../../hooks/useObs';
import {simpleObs} from '../../utils/simpleObs';
import {IAnaEntry} from './anaEntry';
import {useSocket} from '../../hooks/useSocket';
import empty from '../../assets/empty.png';

export const AnaList: FC<{columns: number}> = ({columns}) => {
  const locale = useLocale();
  const [loading, assignments] = useAllAssignments();
  const switchState = useArrowSwitchState(assignments.length, columns);
  const {value: offset} = switchState;
  const width = `calc((100% - var(--spacer-sm) * ${columns - 1}) / ${columns})`;
  const {map, entries} = useMemo(() => {
    const map = new Map<string, IAnaEntry>();
    return {
      map,
      entries: assignments
        .map((a) => {
          let entry = map.get(a.id);
          if (!entry) {
            entry = {
              assignment: a,
              count: simpleObs(undefined),
              status: simpleObs(a.status)
            };
            map.set(a.id, entry);
          }
          return entry;
        })
        .sort(sortEntries)
    };
  }, [assignments]);
  const socket = useSocket();
  useEffect(() => {
    return socket.addListener<IAssignment>('Assignment', (a) => {
      map.get(a.id)?.status.set(a.status);
    });
  }, [socket, map]);
  const isEmpty = !loading && assignments.length === 0;
  const [listNode, setListNode] = useState<HTMLElement | null>(null);
  return (
    <AssignmentPermissionsProvider>
      <AssignmentTypesProvider>
        <div className={'p-mb-3'} css={$header} style={{width}}>
          <Title>
            {locale.eventsTitle}
            {!loading && ` - ${assignments.length}`}
          </Title>
          <ArrowSwitch state={switchState} small />
        </div>
        <HeightProvider node={listNode}>
          {isEmpty ? (
            <div css={$list}>
              <EmptyMessage text={locale.assignmentsEmpty} />
            </div>
          ) : (
            <SliderList<IAnaEntry>
              onNodeMount={setListNode}
              css={$list}
              offset={offset}
              columns={columns}
              entryWidth={width}
              gap={'var(--spacer-sm)'}
              entries={loading ? Array(columns).fill({}) : entries}
              render={
                loading
                  ? ({left, width}) => <Placeholder key={left} style={{left, width}} />
                  : ({entry, left, width}) => <AnaEntry key={entry.assignment.id} entry={entry} style={{left, width}} />
              }
              transition={'500ms ease-out'}
            />
          )}
        </HeightProvider>
      </AssignmentTypesProvider>
    </AssignmentPermissionsProvider>
  );
};
const $header = css`
  & {
    display: flex;
    align-items: baseline;
    padding-left: calc(10rem / var(--bfs));
    padding-right: calc(10rem / var(--bfs));
  }
  & > :first-child {
    flex-grow: 1;
  }
`;

const $list = css`
  & {
    margin-top: calc(-1 * var(--spacer-xs));
    padding-top: var(--spacer-xs);
    padding-left: var(--spacer-xs);
    padding-right: var(--spacer);
    mask-image: linear-gradient(
      to left,
      transparent 0%,
      rgba(0, 0, 0, 1) var(--spacer-sm),
      rgba(0, 0, 0, 1) calc(100% - var(--spacer-xs)),
      transparent 100%
    );

    flex-grow: 1;
    position: relative;
  }
`;

const statusOrder = {
  Started: 0,
  Starting: 1,
  Rejected: 2,
  Stopping: 3,
  Stopped: 4,
  NotStarted: 5
};
const sortEntries = (a: IAnaEntry, b: IAnaEntry) => {
  return statusOrder[a.status.get()] - statusOrder[b.status.get()];
};

const AnaEntry: SC<{entry: IAnaEntry}> = ({entry, className, style}) => {
  const {assignment: ana, count, status} = entry;
  const camera = useEntity<ICamera>(ana.entityType, ana.entityId, 'title');
  const type = useAssignmentType(ana.assignmentTypeId);
  const height = useObs(useHeight());
  const countVal = useObs(count);
  const statusVal = useObs(status);
  return (
    <div className={className} style={{...style, height}} css={$entry}>
      <Card className={'p-mb-1'} mode={modeMap(statusVal)} css={$entry_card}>
        <div>
          <span css={$entry_title} title={ana.title}>
            {upperFirst(ana.title)}
          </span>
          <div css={$entry_count}>
            {isNumber(countVal) ? countVal : <span className={'mdi mdi-20px mdi-loading mdi-spin'} />}
          </div>
        </div>
        <div>
          <div css={$entry_meta}>
            <div>{upperFirst(type?.title)}</div>
            <div>{upperFirst(camera?.title)}</div>
          </div>
          <StatusButton css={$status} id={entry.assignment.id} status={statusVal} />
        </div>
      </Card>
      {type?.name === 'BorderCross' ? (
        <Counter css={$counter} entry={entry} />
      ) : (
        <EventList
          css={$eventList}
          entry={entry}
          style={{height: `calc(100% - 135rem / var(--bfs) - var(--spacer-sm))`}}
        />
      )}
    </div>
  );
};

const $counter = css`
  & {
    margin-top: var(--spacer-sm);
    padding: var(--spacer-xs) var(--spacer-xs) 0 var(--spacer-xs);
  }
`;

const $eventList = css`
  & {
    padding: var(--spacer-xs) calc(1.5 * var(--spacer-xs)) calc(3 * var(--spacer)) calc(1.5 * var(--spacer-xs));
    margin: 0 calc(-1.5 * var(--spacer-xs)) 0 calc(-1.5 * var(--spacer-xs));
    width: calc(100% + 3 * var(--spacer-xs)) !important;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      width: 0;
    }
    mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) calc(100% - 4 * var(--spacer)), rgba(0, 0, 0, 0) 100%);
  }
`;

const modeMap = (status: AssignmentStatus) => {
  switch (status) {
    case AssignmentStatus.Started:
      return 'selected';
    case AssignmentStatus.Rejected:
      return 'error';
    default:
      return 'highlighted';
  }
};

const $entry = css`
  height: 100%;
`;

const $entry_card = css`
  & {
    height: calc(135rem / var(--bfs));
    display: flex;
    flex-direction: column;

    > div {
      display: flex;
      flex-direction: row;
      align-items: flex-end;

      > *:first-child {
        flex-grow: 1;
        margin-right: var(--spacer-xs);
      }
    }

    > div:first-child {
      flex-grow: 1;
      align-items: baseline;
      margin-bottom: var(--spacer-xs);
    }
  }
`;

const $entry_title = css`
  & {
    font-size: var(--font-size-h4);
    max-width: 92%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 500;
    margin: 0;
  }
`;

const $entry_count = css`
  & {
    color: inherit;
    font-size: var(--font-size-h4);
    font-weight: 500;
    margin: 0;
  }
`;

const $entry_meta = css`
  & {
    > div:nth-child(1) {
      font-size: calc(12rem / var(--bfs));

      margin-bottom: calc(5rem / var(--bfs));
    }
    > div:nth-child(2) {
      font-size: calc(14rem / var(--bfs));
      font-weight: 500;
    }
  }
`;

const $status = css`
  & {
    width: auto !important;
    height: auto !important;
    padding: 0 !important;
    overflow: visible;
  }
`;

const Placeholder: SC = ({style, className}) => {
  return (
    <div className={className} style={style} css={$entry}>
      <Card mode={'highlighted'} css={$entry_card} loading />
    </div>
  );
};

const EmptyMessage: SC<{text: string}> = ({className, style, text}) => {
  return (
    <div className={className} style={style} css={$empty}>
      <img src={empty} />
      <h4>{text}</h4>
    </div>
  );
};

const $empty = css`
  & {
    border: calc(1rem / var(--bfs)) solid rgba(var(--text-color-secondary-raw), 0.2);
    border-radius: var(--border-radius);

    > img {
      display: block;
      width: 100%;
      height: calc(300rem / var(--bfs));
      max-height: calc(100% - var(--font-size-h4) - var(--spacer));
      object-fit: scale-down;
    }
    > h4 {
      margin: calc(-1 * var(--spacer)) 0 0 0;
      padding: 0 var(--spacer) var(--spacer);
      font-size: var(--font-size-h4);
      text-align: center;
      color: var(--text-color);
      font-weight: 400;
    }
  }
`;
