import {createCamerasConnection, IAssignment, listEntities} from '@netvision/lib-api-gateway';
import {useEffect, useState} from 'react';
import {useFilter} from '../components/filters/FilterProvider';
import {composeEnum} from '../utils/ngsiComposeQ';

const empty: IAssignment[] = [];

export const useAllAssignments = () => {
  const [loading, setLoading] = useState(true);
  const [all, setAll] = useState<IAssignment[]>(empty);
  const {cameras} = useFilter();
  useEffect(() => {
    const query = composeEnum('entityId')(cameras) || '';
    setLoading(true);
    setAll(empty);
    listEntities<IAssignment>(createCamerasConnection(), {
      type: 'Assignment',
      q: 'entityType==Camera;' + query,
      limit: 1000,
      orderBy: 'id'
    })
      .then((res) => {
        setAll(res.results);
      })
      .catch((e) => {
        console.error(e);
        setAll(empty);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [cameras]);
  return [loading, all] as const;
};
