/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import {useCounter} from '../../hooks/useCounter';
import {Card} from '../ui/Card';
import {SC} from '../../models/util';
import {useLocale} from '../../hooks/useLocale';
import {Fragment, useEffect} from 'react';
import {IAnaEntry} from './anaEntry';

export const Counter: SC<{entry: IAnaEntry}> = ({entry}) => {
  const {objectTypeLocale} = useLocale();
  const counters = useCounter(entry.assignment.id);
  useEffect(() => {
    entry.count.set(counters.reduce((a, b) => a + (b?.count || 0), 0));
  }, [entry, counters]);
  return counters.length > 0 ? (
    <Card css={$main}>
      {counters.filter(isNotUndefined).map((c, index) => {
        return (
          <Fragment key={c.direction}>
            {index !== 0 && <div css={$separator} />}
            <div css={$counter}>
              <span>{c.type.map((t) => objectTypeLocale[t]).join(', ')}</span>
              <div>
                <span className={c.direction === 'Direct' ? 'mdi mdi-20px mdi-login' : 'mdi mdi-20px mdi-logout'} />
              </div>
              <span>{c.count}</span>
            </div>
          </Fragment>
        );
      })}
    </Card>
  ) : null;
};

const isNotUndefined = <T,>(v: T | undefined): v is Exclude<T, undefined> => v !== undefined;

// language=SCSS
const $main = css`
  & {
    position: relative;
  }
`;

const $counter = css`
  display: flex;
  align-items: center;
  color: var(--text-color);
  font-weight: 500;
  font-size: calc(15rem / var(--bfs));

  & > *:nth-child(1) {
    width: min-content;
    margin-right: var(--spacer-xs);
  }

  & > *:nth-child(2) {
    flex-grow: 1;
    color: var(--text-color-secondary);
    margin-right: var(--spacer-xs);
  }
`;

const $separator = css`
  opacity: 0.5;
  background: var(--text-color-secondary);
  width: 100%;
  height: calc(1rem / var(--bfs));
  margin: calc(20rem / var(--bfs)) 0;
  padding: 0 calc(20rem / var(--bfs));
`;
