import {useCallback, useEffect, useState} from 'react';

export const useToPx = () => {
  const [ratio, setRatio] = useState(getRemRatio);
  useEffect(() => {
    const onUpdate = () => setRatio(getRemRatio());
    onUpdate();
    return subscribe(onUpdate);
  }, []);
  return useCallback(
    (rem: number) => {
      return rem * ratio;
    },
    [ratio]
  );
};

const getRemRatio = () => Number(document.getElementById('rem-gauge')?.getAttribute('rem') ?? 1);
const subscribe = (cb: () => void) => {
  const el = document.getElementById('rem-gauge');
  if (el) {
    el.addEventListener('update', cb);
    return () => {
      el.removeEventListener('update', cb);
    };
  } else {
    return undefined;
  }
};
