import {useCallback, useEffect, useState} from 'react';
import {
  AssignmentEventType,
  Connection,
  createCamerasConnection,
  IAssignment,
  IAssignmentEvent,
  IAssignmentEventCounter,
  listEntities
} from '@netvision/lib-api-gateway';
import {useFilter} from '../components/filters/FilterProvider';
import {composeEnum, composeRange} from '../utils/ngsiComposeQ';
import {IAssignmentEventType} from '../models/IAssignmentEventType';

const empty = new Map();

interface SeparatedList {
  countable: {
    qType: 'AssignmentEventCounter';
    eventTypes: IAssignmentEventType[];
  };
  uncountable: {
    qType: 'AssignmentEvent';
    eventTypes: IAssignmentEventType[];
  };
}

type StatsResponse = [AssignmentEventType, number];

export const useEventStats = (list: IAssignmentEventType[]) => {
  const [loading, setLoading] = useState(false);
  const [eventMap, setEventMap] = useState<Map<string, number>>(empty);
  const {cameras, timestamp} = useFilter();

  const qConfig = useCallback(
    (eventType: string) =>
      [
        'entityType==Camera',
        `eventType==${eventType}`,
        composeEnum('assignment.entityId')(cameras),
        composeRange('timestamp')(timestamp)
      ]
        .filter(Boolean)
        .join(';'),
    [cameras, timestamp]
  );

  const fetchCountable = async (config: SeparatedList['countable'], conn: Connection): Promise<StatsResponse[]> => {
    try {
      const counters = await Promise.all(
        config.eventTypes.map((type) =>
          listEntities<IAssignmentEventCounter>(conn, {
            limit: 1,
            type: config.qType,
            q: qConfig(type.name),
            attrs: 'assignmentType',
            orderBy: '!timestamp'
          })
        )
      );

      const preparedCounters = counters.reduce<IAssignmentEventCounter[]>((acc, next) => {
        next.results.length && acc.push(...next.results);
        return acc;
      }, []);

      const assignments = await Promise.all(
        preparedCounters.map((counter) =>
          listEntities<IAssignment>(conn, {
            limit: 1000,
            type: 'Assignment',
            q: `entityType==Camera;assignmentTypeName==${counter.assignmentType}`,
            attrs: 'id',
            orderBy: 'id'
          })
        )
      );

      const preparedAssignments = assignments.reduce<IAssignment[]>((acc, next) => {
        next.results.length && acc.push(...next.results);
        return acc;
      }, []);

      const events = await Promise.all(
        preparedAssignments.map((assignment) =>
          listEntities<IAssignmentEventCounter>(conn, {
            limit: 1,
            type: 'AssignmentEventCounter',
            q: `assignmentId==${assignment.id};${composeRange('timestamp')(timestamp)}`,
            attrs: 'count,eventType',
            orderBy: '!timestamp'
          })
        )
      );

      const preparedEvents = events.reduce<Map<AssignmentEventType, number>>((acc, next) => {
        if (next.results[0]?.count) {
          acc.has(next.results[0].eventType)
            ? acc.set(next.results[0].eventType, (acc.get(next.results[0].eventType) || 0) + next.results[0].count)
            : acc.set(next.results[0].eventType, next.results[0].count);
        }

        return acc;
      }, new Map());

      return Array.from(preparedEvents.entries());
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const fetchUncountable = async (config: SeparatedList['uncountable'], conn: Connection): Promise<StatsResponse[]> => {
    return Promise.all(
      config.eventTypes.map((type) =>
        listEntities<IAssignmentEvent>(conn, {
          limit: 1,
          type: config.qType,
          q: qConfig(type.name),
          count: true,
          attrs: 'id,eventType'
        })
      )
    )
      .then((res) =>
        res.reduce<StatsResponse[]>((acc, next) => {
          if (next.results.length) {
            acc.push([next.results[0].eventType, next.count]);
          }

          return acc;
        }, [])
      )
      .catch((error) => {
        console.error(error);
        return [];
      });
  };

  useEffect(() => {
    setLoading(true);
    setEventMap(empty);

    const connection = createCamerasConnection();

    const {countable, uncountable} = list.reduce<SeparatedList>(
      (acc, next) => {
        next.counting ? acc.countable.eventTypes.push(next) : acc.uncountable.eventTypes.push(next);

        return acc;
      },
      {
        countable: {qType: 'AssignmentEventCounter', eventTypes: []},
        uncountable: {qType: 'AssignmentEvent', eventTypes: []}
      }
    );

    Promise.all([fetchCountable(countable, connection), fetchUncountable(uncountable, connection)])
      .then((res) => setEventMap(new Map(res.flat())))
      .finally(() => setLoading(false));
  }, [list, cameras, timestamp]);

  return [loading, eventMap] as const;
};
