import {ILocale} from '../locale';

const stringToNumber = (input?: string | number): number => {
  if (!input || typeof input === 'number') return Number(input);
  return Number(input.replace(/\D/g, ''));
};

export const formatNumber = (locale: ILocale, input?: number | string): string => {
  let inputNum = stringToNumber(input);

  if (isNaN(stringToNumber(inputNum))) return '';
  if (inputNum < 1_000_000) return inputNum.toLocaleString('ru-RU');

  const orderSet = new Set([1, 10, 100]);
  const suffixes = ['', ...Object.values(locale.largeNumberAbbrs)];
  const suffixIndex = Math.floor((inputNum.toString().length - 1) / 3);
  const formattedNum = (inputNum / Math.pow(1000, suffixIndex)).toFixed(3);

  return (
    (/^\d*\.0*$/.test(formattedNum) ? formattedNum.replace(/\.0*$/, '') : formattedNum.replace(/\./, ' ')) +
    ' ' +
    suffixes[orderSet.has(+formattedNum) ? suffixIndex : suffixIndex - 1]
  );
};
