declare module '@netvision/lib-api-gateway' {
  interface IAssignment {
    status: AssignmentStatus;
    assignmentTypeName: string;
  }
}

export enum AssignmentEventStatus {
  NotHandled = 'NotHandled',
  FalsePositive = 'FalsePositive',
  Handled = 'Handled',
  ViolationDetected = 'ViolationDetected',
  Alert = 'ViolationDetected'
}

export enum AssignmentStatus {
  'Stopped' = 'Stopped',
  'NotStarted' = 'NotStarted',
  'Started' = 'Started',
  'Starting' = 'Starting',
  'Stopping' = 'Stopping',
  'Rejected' = 'Rejected'
}
