/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import {ReactNode, useCallback} from 'react';

export interface ISliderListProps<T> {
  className?: string;
  entries: T[];
  render: (props: {entry: T; left: string; width: string; index: number}) => ReactNode;
  offset: number;
  columns: number;
  entryWidth?: string;
  gap: string;
  // f.e. 500ms ease-out
  transition?: string;
  onNodeMount?: (el: HTMLElement | null) => void;
}

export const SliderList = <T,>(props: ISliderListProps<T>) => {
  const {
    className,
    entries,
    render,
    offset,
    columns,
    gap,
    entryWidth,
    transition = '500ms ease-out',
    onNodeMount
  } = props;
  const width = entryWidth || `calc((100% - ${gap} * ${columns - 1}) / ${columns})`;
  const handleRef = useCallback(
    (el: HTMLElement | null) => {
      typeof onNodeMount === 'function' && onNodeMount(el);
    },
    [onNodeMount]
  );
  return (
    <div ref={handleRef} className={className} css={$list}>
      <div style={{left: `calc((${width} + ${gap}) * ${-offset})`, transition: `left ${transition}`}}>
        {entries.map((entry, index) => {
          if (offset <= index + columns * 2 && index - columns * 2 <= columns - 1 + offset) {
            const left = `calc((${width} + ${gap}) * ${index})`;
            return render({index, entry, left, width});
          }
          return null;
        })}
      </div>
    </div>
  );
};

// language=SCSS
const $list = css`
  & {
    overflow: hidden;
  }
  & > div {
    position: relative;
    height: 100%;
    width: 100%;
    transition: left 500ms ease-out;

    & > * {
      position: absolute;
      margin-right: var(--spacer-sm);
    }
    & > * {
      margin-right: 0;
    }
  }
`;
