import {AssignmentStatus} from './models/AssignmentStatus';
import {ObjectType} from '@netvision/lib-api-gateway';

const datePicker = {
  firstDayOfWeek: 1,
  dayNames: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
  dayNamesShort: ['Вос', 'Пон', 'Вто', 'Сре', 'Чет', 'Пят', 'Суб'],
  dayNamesMin: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
  monthNames: [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь'
  ],
  monthNamesShort: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Нов', 'Дек'],
  today: 'Сегодня',
  clear: 'Очистить',
  weekHeader: 'Неделя'
};

const objectTypeLocale: Record<ObjectType, string> = {
  [ObjectType.Person]: 'Люди',
  [ObjectType.Car]: 'Автомобили',
  [ObjectType.Bicycle]: 'Велосипеды',
  [ObjectType.Motorcycle]: 'Мотоциклы',
  [ObjectType.Animal]: 'Животные',
  [ObjectType.Roadsign]: 'Дорожные знаки'
};

const assignmentStatusLocale: Record<AssignmentStatus, string> = {
  Rejected: 'Отказано',
  Stopped: 'Остановлена',
  NotStarted: 'Не запущена',
  Starting: 'Запускается',
  Stopping: 'Останавливается',
  Started: 'Запущена'
};

const largeNumberAbbrs = {
  thousands: 'тыс.',
  millions: 'млн.',
  billions: 'млрд.',
  trillions: 'трлн.',
  trilliards: 'трлрд.'
};

export const defaultLocale = {
  eventsTitle: 'Аналитики',
  incidentsTitle: 'Происшествия',
  incidentsTitleEmpty: 'Проиcшествий не найдено',
  eventStatEmpty: 'Событий не зафиксировано',
  assignmentsEmpty: 'Аналитики отсутствуют',
  allCameras: 'Все камеры',
  search: 'Поиск',
  anyTime: 'За все время',
  assignmentStatusLocale,
  cancel: 'Отмена',
  delete: 'Удалить',
  deleteMessage: 'Удалить аналитику с объекта?',
  start: 'Запустить',
  stop: 'Остановить',
  restart: 'Перезапустить',
  title: 'Аналитики',
  assignmentNameLabel: 'Аналитика',
  assignmentTypeLabel: 'Тип аналитики',
  priorityLabel: 'Приоритет',
  objectLabel: 'Объект',
  statusLabel: 'Статус',
  searchByName: 'Поиск по названию',
  objectTypeLocale,
  largeNumberAbbrs,
  datePicker
};

export type ILocale = typeof defaultLocale;
