/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import {AnaList} from './anaList/AnaList';
import {useObs} from '../hooks/useObs';
import {FC, useMemo} from 'react';
import {IObs, mapObs} from '../utils/simpleObs';
import {useToPx} from '../hooks/useToPx';
import {E2EModule} from '../__test__';

export const Right: FC<{widthObs: IObs<number>}> = ({widthObs}) => {
  const toPx = useToPx();
  const columns = useObs(
    useMemo(
      () =>
        mapObs((width) => {
          return Math.max(1, Math.floor(((width / 3) * 2) / toPx(300)));
        }, widthObs),
      [toPx, widthObs]
    )
  );
  return (
    <section data-cy={E2EModule.attributes.allAnalytics} css={$right}>
      <AnaList columns={columns} />
    </section>
  );
};

const $right = css`
  & {
    position: relative;
    grid-column: 2/2;
    grid-row: 2/2;

    display: flex;
    flex-direction: column;
  }
`;
