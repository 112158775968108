/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import {ReactNode, useEffect, useRef, useState} from 'react';
import {createPortal} from 'react-dom';
import {simpleObs} from '../utils/simpleObs';
import {Top} from './Top';
import {Right} from './Right';
import {Left} from './Left';
import {FilterProvider} from './filters/FilterProvider';
import {Filters} from './filters/Filters';
import {ExtraButtonsAdapter} from './ExtraButtonsAdapter';
import ResizeObserver from 'resize-observer-polyfill';

const getSizeObs = () => simpleObs(0);

const NavbarPortal = ({children, navBar}: {children: ReactNode; navBar: Element}) => {
  const navChildren = Array.from(navBar.children);
  const warningMessage = navChildren.find((node) => node.classList.contains('p-message'));
  let filtersWrapperElement = document.getElementById('anaFilters');

  if (!filtersWrapperElement) {
    filtersWrapperElement = document.createElement('div');
    filtersWrapperElement.style.marginLeft = 'calc(30rem / var(--bfs))';
    filtersWrapperElement.id = 'anaFilters';
  }

  useEffect(() => {
    if (warningMessage) {
      warningMessage.before(filtersWrapperElement as Node);
    } else {
      navBar.appendChild(filtersWrapperElement as Node);
    }
    return () => {
      navBar.removeChild(filtersWrapperElement as Node);
    };
  }, [filtersWrapperElement, navBar]);

  return createPortal(children, filtersWrapperElement);
};

export const Main = () => {
  const mainRef = useRef<HTMLDivElement | null>(null);
  const [navbarElement, setNavbarElement] = useState<Element | null>(null);
  const [widthObs] = useState(getSizeObs);
  const [heightObs] = useState(getSizeObs);
  const checkAndSetNavbar = () => {
    const mainMenuCollection = document.getElementsByClassName('main-menu');
    if (mainMenuCollection?.length > 0) {
      setNavbarElement(mainMenuCollection[0]);
      return;
    }
    setTimeout(() => checkAndSetNavbar(), 100);
  };
  useEffect(() => {
    let abort = false;
    if (!abort) checkAndSetNavbar();
    return () => {
      abort = true;
    };
  }, []);
  useEffect(() => {
    const main = mainRef.current;
    if (!main) {
      return undefined;
    }
    const ro = new ResizeObserver(([entry]: ResizeObserverEntry[]) => {
      const {width, height} = entry.contentRect;
      widthObs.set(width);
      heightObs.set(height);
    });
    ro.observe(main);
    return () => {
      ro.unobserve(main);
      ro.disconnect();
    };
  }, [widthObs, heightObs]);
  return (
    <div>
      <FilterProvider>
        {navbarElement && (
          <NavbarPortal navBar={navbarElement}>
            <header css={s.headerPos}>
              <Filters />
              <ExtraButtonsAdapter />
            </header>
          </NavbarPortal>
        )}
        <main ref={mainRef} css={[s.grid, s.position]}>
          <Top widthObs={widthObs} />
          <Left />
          <Right widthObs={widthObs} />
        </main>
      </FilterProvider>
    </div>
  );
};

// language=SCSS
const s = {
  position: css`
    & {
      position: relative;
      height: 100vh;
      padding: calc(90rem / var(--bfs)) 0 0;
    }
  `,
  headerPos: css`
    & {
      display: flex;
    }
  `,
  grid: css`
    & {
      display: grid;
      grid-template-columns: 1fr 2fr;
      grid-template-rows: calc(150rem / var(--bfs)) auto;
    }
  `
};
