/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import {useRef, useState, useEffect, useMemo} from 'react';
import {InputText} from 'primereact/inputtext';
import {isPropArrayOfTypes} from '../../utils/basicValidators';
import {SC} from '../../utils/SC';
import {cx} from '../../utils/cx';
import {useOverlay} from './useOverlay';
import {E2EModule} from '../../__test__';
import {useWidgetProps} from '../../Root';
import {PublishPayload} from '@netvision/lib-types-frontend';
import {history, SearchParams} from '@netvision/lib-history';
import {useLocation} from '../../providers/LocationProvider';
import {name} from '../../../package.json';

export type ITimeFilterValue = [number, number];

export interface ITimeFilter {
  min?: number | 'now';
  max?: number | 'now';
}

export const TimeFilter: SC<{
  value: ITimeFilterValue;
  onChange: (newValue: ITimeFilterValue) => void;
  anyString: string;
}> = ({className, style, value, onChange, anyString}) => {
  const {
    areas,
    mountChildren,
    props: {defaultPeriodMs, eventBus}
  } = useWidgetProps();
  const [eventBusID, setEventBusID] = useState<string>(null!);
  const {search} = useLocation();
  const calendarRef = useRef<HTMLDivElement | null>(null);
  const currentValueRef = useRef(value);
  const onChangeRef = useRef(onChange);
  const normalized = rangeToDateRange(value);
  const inputValue = getStingValue(normalized, anyString);
  const calendarValue = normalized;

  const isDefaultRange = useMemo(() => (value[1] - value[0]) / defaultPeriodMs === 1, [value]);

  currentValueRef.current = value;
  onChangeRef.current = onChange;

  const [ref, visible, setVisible] = useOverlay();

  const onClear = () => {
    onChange([Date.now() - defaultPeriodMs, Date.now()]);
    setVisible(false);
    const parsedFilters = SearchParams.parse(search);

    if (isPropArrayOfTypes<number, typeof parsedFilters, string>(parsedFilters, 'timestamp', 'number')) {
      delete parsedFilters.timestamp;
      history.push({
        search: SearchParams.stringify(parsedFilters),
        hash: history.location.hash
      });
    }
  };

  const applyDateTime = (payload: PublishPayload<{values: ITimeFilterValue}>) => {
    let newValue: ITimeFilterValue;

    if (!payload.data.values.length) {
      newValue = [Date.now() - defaultPeriodMs, Date.now()];
    } else {
      const [intervalStart, intervalEnd] = payload.data.values;
      newValue = [intervalStart, intervalEnd || intervalStart];
    }

    onChangeRef.current(newValue);
  };

  useEffect(() => {
    if (eventBus) {
      setEventBusID(eventBus.addUniqueSubscriberMap());
    }
  }, [eventBus]);

  useEffect(() => {
    if (eventBusID) {
      // @ts-ignore
      eventBus.subscribe(eventBusID, 'applyDateTime', applyDateTime);
    }

    return () => {
      if (eventBusID) {
        // @ts-ignore
        eventBus.unsubscribe(eventBusID, 'applyDateTime', applyDateTime);
      }
    };
  }, [eventBus, eventBusID]);

  useEffect(() => {
    const {current: mountingPoint} = calendarRef;
    const area = areas?.find(({name}) => name === 'calendar');

    if (!mountingPoint || !mountChildren || !visible || !area) return;

    return mountChildren(
      mountingPoint,
      area.children.map((child) => ({
        ...child,
        props: {
          ...('props' in child ? child.props : {}),
          parentName: name,
          selectionMode: 'range',
          eventBusID,
          style: {
            background:
              'radial-gradient(circle farthest-corner at bottom right, var(--bg-gradient-fallback) 0%, var(--bg-gradient-dark-fallback) 100%)',
            zIndex: 4000,
            borderRadius: 'var(--border-radius)',
            width: 'calc(400rem / var(--bfs))',
            boxShadow:
              'rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px'
          },
          initialDateTime: calendarValue
        }
      }))
    );
  }, [mountChildren, areas, visible]);

  return (
    <div
      ref={ref}
      data-cy={E2EModule.attributes.dateFilter}
      className={cx(['p-fluid', className])}
      css={$main}
      style={style}>
      <span className={'p-input-raised p-input-icon-left p-input-icon-right'}>
        <i className={'mdi mdi-18px mdi-calendar-month-outline'} />
        <InputText
          onClick={() => setVisible((prev) => !prev)}
          className={'p-highlighted'}
          readOnly
          value={inputValue}
        />
        {!isDefaultRange ? (
          <i role={'button'} onClick={onClear} className={'mdi mdi-18px mdi-close'} css={$pointer} />
        ) : (
          <i className={'mdi mdi-18px mdi-menu-down'} />
        )}
      </span>
      {visible && <div ref={calendarRef} />}
    </div>
  );
};

const $pointer = css`
  & {
    cursor: pointer;
  }
`;

const $main = css`
  & {
    position: relative;
    overflow: visible;
  }
`;

const isSameDay = (a: Date, b: Date) =>
  a.getDate() === b.getDate() && a.getMonth() === b.getMonth() && a.getFullYear() === b.getFullYear();

type IRange = [number, number];
type IDateRange = [Date, Date];

const rangeToDateRange = (value: IRange): IDateRange => {
  const [v1, v2] = value;
  return [new Date(v1), new Date(v2)];
};

const formatter = Intl.DateTimeFormat('ru-ru', {day: 'numeric', month: 'short'});

const getStingValue = (value: IDateRange, anyString: string) => {
  if (value === null) {
    return anyString;
  } else {
    if (isSameDay(value[0], value[1])) {
      return formatter.format(value[0]);
    } else {
      return `${formatter.format(value[0])} - ${formatter.format(value[1])}`;
    }
  }
};
