/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import {SC} from '../../../models/util';
import {FC, ReactElement, useEffect, useLayoutEffect, useState} from 'react';

import {gatewayPath, fiwareOptions, createCamerasConnection, IAssignmentEvent} from '@netvision/lib-api-gateway';

const previewUrl = (cameraId: string, time: number) => {
  return `${gatewayPath}/vms/v1/streams/${cameraId}/snapshot?start=${Math.floor(time / 1000)}`;
};

const fetchPosterVideo = (posterUrl: string, signal?: AbortSignal) => {
  return fetch(posterUrl, {
    mode: 'cors',
    headers: {
      'Fiware-Service': fiwareOptions.service,
      'Fiware-ServicePath': fiwareOptions.servicePath
    },
    signal
  }).then((res) => {
    if (res.status === 200 && res.headers.get('Content-Type') === 'video/mp4') {
      return res.blob();
    } else {
      console.error('Preview cannot be fetched');
      return null;
    }
  });
};

const fetchFile = (id: string, signal?: AbortSignal): Promise<Blob | 'notFound' | 'error'> => {
  // @ts-ignore
  const {url, headers} = createCamerasConnection();
  return fetch(`${url.href}v1/assignmentEventPhotos/${id}:scene`, {
    method: 'GET',
    headers,
    signal
  }).then((res) => {
    if (res.status === 404) {
      return 'notFound';
    }
    const cType = res.headers.get('content-type');
    if (cType && cType.startsWith('image/')) {
      // ts cannot join Promise
      return (res.blob() as unknown) as Blob;
    }
    return 'error';
  });
};

const doUseExternalFile = (event: IAssignmentEvent) => {
  return event.assignment && !['Metadata', undefined].includes(event.assignment.parameters.syncMode);
};

export const Preview: FC<{event: IAssignmentEvent; fallback: ReactElement}> = ({event, fallback}) => {
  const useExternalFile = doUseExternalFile(event);
  if (useExternalFile) {
    return <ExternalFilePreview event={event} fallback={fallback} />;
  }
  const cameraId = event.assignment?.entityId;
  if (cameraId) {
    return <CameraPreview cameraId={cameraId} time={event.timestamp} fallback={fallback} />;
  }
  return fallback;
};

const externalFilePreviewCache = new Map<string, Blob | null>();

const ExternalFilePreview: FC<{event: IAssignmentEvent; fallback: ReactElement}> = ({event, fallback}) => {
  const [src, setSrc] = useState('');
  useEffect(() => {
    setSrc('');
    const key = event.id;
    const cached = cameraPreviewCache.get(key);
    if (cached) {
      const url = URL.createObjectURL(cached);
      setSrc(url);
      return () => {
        URL.revokeObjectURL(url);
      };
    } else if (cached === undefined) {
      let aborted = false;
      const controller = new AbortController();
      const link = fetchFile(event.id, controller.signal).then((res) => {
        let url;
        if (res instanceof Blob) {
          url = URL.createObjectURL(res);
          cameraPreviewCache.set(event.id, res);
          if (!aborted) {
            setSrc(url);
          }
        } else {
          cameraPreviewCache.set(event.id, null);
        }
        return url;
      });
      return () => {
        aborted = true;
        controller.abort();
        link.then((url) => {
          if (typeof url === 'string') {
            URL.revokeObjectURL(url);
          }
        });
      };
    }
    return undefined;
  }, [event]);
  return src ? <img src={src} alt={event.id} css={$preview} /> : fallback || null;
};

// language=SCSS
const $external = css`
  img {
    display: block;
    height: 100%;
    width: 100%;
    object-fit: scale-down;
    pointer-events: none;
    transition: opacity ease 200ms 100ms;
  }
`;

const cameraPreviewCache = new Map<string, Blob | null>();

const CameraPreview: SC<{cameraId: string; time: number; fallback: ReactElement}> = ({cameraId, time, fallback}) => {
  const [src, setSrc] = useState('');
  useEffect(() => {
    if (src) {
      return () => {
        URL.revokeObjectURL(src);
      };
    }
    return undefined;
  }, [src]);
  useLayoutEffect(() => {
    let aborted = false;
    const controller = new AbortController();
    const key = cameraId + time;
    const cached = cameraPreviewCache.get(key);
    if (cached) {
      setSrc(URL.createObjectURL(cached));
    } else if (cached === undefined) {
      fetchPosterVideo(previewUrl(cameraId, time), controller.signal).then((blob) => {
        cameraPreviewCache.set(cameraId + time, blob);
        if (blob && !aborted) {
          setSrc(URL.createObjectURL(blob));
        }
      });
    }
    return () => {
      aborted = true;
      controller.abort();
    };
  }, [cameraId, time]);
  return src ? <video src={src} css={$preview} /> : fallback;
};

// language=SCSS
const $preview = css`
  & {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: var(--border-radius);
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
`;
