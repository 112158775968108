/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import {FC, useMemo} from 'react';
import {IObs, mapObs} from '../utils/simpleObs';
import {useLocale} from '../hooks/useLocale';
import {useObs} from '../hooks/useObs';
import {useEventStats} from '../hooks/useEventStats';
import {IStatEntry, Stats} from './stats/Stats';
import {IAssignmentEventType} from '../models/IAssignmentEventType';
import {useEventTypes} from '../providers/EventTypesProvider';
import {useLang} from '../providers/LangProvider';
import {upperFirst} from 'lodash-es';
import {useToPx} from '../hooks/useToPx';
import {E2EModule} from '../__test__';

export const Top: FC<{widthObs: IObs<number>}> = ({widthObs}) => {
  const locale = useLocale();
  const toPx = useToPx();
  const columns = useObs(
    useMemo(
      () =>
        mapObs((width) => {
          if (width <= 768) {
            return 2;
          } else if (width <= toPx(1280)) {
            return 3;
          } else if (width <= toPx(1440)) {
            return 4;
          } else {
            return 5;
          }
        }, widthObs),
      [toPx, widthObs]
    )
  );
  const eventTypes = useEventTypes();
  const list = useMemo<IAssignmentEventType[]>(
    () => Array.from(eventTypes.values()).filter((et) => et.startsWith === undefined),
    [eventTypes]
  );
  const [loading, countMap] = useEventStats(list);
  const lang = useLang();
  const entries = list
    .filter((value) => (countMap.get(value.name) || 0) > 0)
    .map((value) => eventTypeToStat(value, lang, countMap));
  return (
    <section css={$top} data-cy={E2EModule.attributes.allEvents}>
      <Stats loading={loading} columns={columns} entries={entries} emptyText={locale.eventStatEmpty} />
    </section>
  );
};

// language=SCSS
const $top = css`
  & {
    grid-column: 1/3;
    grid-row: 1/1;
  }
`;

const eventTypeToStat = (eventType: IAssignmentEventType, lang: string, countMap: Map<string, number>): IStatEntry => {
  const locale = eventType[`locale${upperFirst(lang.toLocaleLowerCase())}` as 'localeRu'] ?? eventType.localeRu;
  const countTitle = locale?.countTitle || locale?.title || eventType?.title || eventType?.name;
  const countUnit = locale?.countUnit;
  const value = countMap.get(eventType.name);
  const unit =
    (countUnit && typeof value === 'number' && countUnit.find(([end]) => value.toString().endsWith(end))?.[1]) ||
    undefined;
  return {
    title: countTitle ?? '',
    icon: eventType.icon?.iconClass ?? '',
    iconColor: eventType.icon?.color ?? '',
    value,
    unit: unit
  };
};
