import React, {createContext, FC, useContext} from 'react';
import {useEntityMap} from '../hooks/useEntityMap';
import {IEntity} from '@netvision/lib-api-gateway';

type IEntityListProvider<E extends IEntity> = {
  Provider: FC;
  useProvider: () => Map<string, E>;
};

export const createEntityMapProvider = <E extends IEntity>(
  type: E['type'],
  getKey: (en: E) => string,
  refine?: (en: E) => E
): IEntityListProvider<E> => {
  const ctx = createContext<Map<string, E>>(null!);
  const Provider: IEntityListProvider<E>['Provider'] = ({children}) => {
    const eventTypes = useEntityMap<E>(type, getKey, refine);
    return <ctx.Provider value={eventTypes}>{children}</ctx.Provider>;
  };
  const useProvider = () => useContext(ctx);
  return {
    Provider,
    useProvider
  };
};
