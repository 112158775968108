import {IBorderCrossEvent, listEntities, ObjectType, createCamerasConnection} from '@netvision/lib-api-gateway';
import {useCallback, useEffect, useState} from 'react';
import {isNumber, isString} from 'lodash-es';
import {composeRange} from '../utils/ngsiComposeQ';
import {useFilter} from '../components/filters/FilterProvider';

type ICounter = {count: number; type: ObjectType[]; direction: 'Reverse' | 'Direct'};

export const useCounter = (assignmentId: string) => {
  const {timestamp} = useFilter();
  const [direct, setDirect] = useState<ICounter | undefined>();
  const [reverse, setReverse] = useState<ICounter | undefined>();
  const update = useCallback((ev: IBorderCrossEvent) => {
    const {direction, count} = ev;
    const type = ev.assignment?.parameters?.typesForControl;
    if (isString(direction) && isNumber(count) && type) {
      if (direction === 'Reverse') {
        setDirect({count, type, direction});
      } else {
        setReverse({count, type, direction});
      }
    }
  }, []);
  useEffect(() => {
    setDirect(undefined);
    setReverse(undefined);
    fetchAssignmentEventCounter(assignmentId, timestamp).then((res) => res && update(res));
  }, [assignmentId, update, timestamp]);

  return [direct, reverse].filter(Boolean);
};

const fetchAssignmentEventCounter = (id: string, time: [number, number]) =>
  listEntities<IBorderCrossEvent>(createCamerasConnection(), {
    type: 'AssignmentEventCounter',
    q: `assignmentId==${id};${composeRange('timestamp')(time)}`,
    orderBy: '!timestamp',
    limit: 1
  }).then((res) => (res.results.length > 0 ? res.results[0] : null));
