import React, {ReactElement, useEffect, useRef, useState, CSSProperties, ReactNode} from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import {useAsRef} from '../../hooks/useAsRef';
import {useToPx} from '../../hooks/useToPx';

// TODO convert usages of SimpleVirtualList to SimpleVirtualListV2
export const SimpleVirtualList: <T>(props: {
  entries: T[];
  height: number;
  gap: number;
  render: (props: {entry: T; top: number; height: number; index: number}) => ReactNode;
  className?: string;
  style?: CSSProperties;
  endThreshold?: number;
  onScrollEnd?: (windowSize: number) => void;
  overScan?: number;
}) => ReactElement | null = ({
  entries,
  gap,
  height,
  render,
  className,
  onScrollEnd,
  endThreshold = 1,
  overScan = 1,
  style
}) => {
  const toPx = useToPx();
  gap = toPx(gap);
  height = toPx(height);
  const totalHeight = entries.length * height + entries.length * (gap - 1);
  const ref = useRef<HTMLDivElement | null>(null);
  const [window, setWindow] = useState({top: 0, bottom: 0});
  const isEmpty = entries.length === 0;
  useEffect(() => {
    if (isEmpty) {
      return undefined;
    }
    const el = ref.current;
    if (!el) {
      return undefined;
    }
    let rafId = 0;
    const updateWindow = () => {
      if (rafId !== 0) {
        cancelAnimationFrame(rafId);
      }
      rafId = requestAnimationFrame(() => {
        rafId = 0;
        let {scrollTop: top, clientHeight} = el;
        top = Math.max(top, 0);
        const bottom = top + clientHeight;
        setWindow({top, bottom});
      });
    };
    el.addEventListener('scroll', updateWindow, {passive: true});
    const resizeOb = new ResizeObserver(updateWindow);
    resizeOb.observe(el);
    return () => {
      resizeOb.unobserve(el);
      resizeOb.disconnect();
      // @ts-ignore
      el.removeEventListener('scroll', updateWindow, {passive: true});
    };
  }, [isEmpty]);
  const startIndex = Math.max(0, Math.floor(window.top / (height + gap)) - overScan);
  const endIndex = Math.min(entries.length, Math.ceil(window.bottom / (height + gap)) + overScan);
  // onEnd
  const windowSizeRef = useAsRef(endIndex - startIndex + 1);
  const onScrollEndRef = useAsRef(onScrollEnd);
  const numberOfEntries = entries.length;
  useEffect(() => {
    const {current: onEnd} = onScrollEndRef;
    if (typeof onEnd === 'function' && numberOfEntries > 0 && numberOfEntries - endIndex <= endThreshold) {
      onEnd(windowSizeRef.current);
    }
  }, [endThreshold, windowSizeRef, onScrollEndRef, endIndex, numberOfEntries]);
  return (
    <div ref={ref} className={className} style={{overflow: 'auto', width: '100%', ...style}}>
      <div style={{height: totalHeight, position: 'relative'}}>
        {entries
          .slice(startIndex, endIndex)
          .map((entry, index) => render({entry, top: (startIndex + index) * (height + gap), height, index}))}
      </div>
    </div>
  );
};
