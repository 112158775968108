import React, {ReactElement, useEffect, useRef, useState, CSSProperties, FC, useCallback} from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import {useToPx} from '../../hooks/useToPx';

type Obj = Record<string, unknown>;

export type IVirtualListProps<T extends Obj, C> = {
  height: number;
  gap: number;
  items: T[];
  keyProp: keyof T;
  Child: FC<IVirtualListChildProps<T, C>>;
  ListEl: 'div' | 'ul' | 'ol';
  context: C;
  className?: string;
  style?: CSSProperties;
  endThreshold?: number;
  onScrollEnd?: (windowSize: number) => void;
  overScan?: number;
};

export type IVirtualListChildProps<T extends Obj, C> = {
  item: T;
  top: number;
  height: number;
  index: number;
  context: C;
};

export const SimpleVirtualList = <T extends Obj, C>({
  items,
  keyProp,
  gap,
  height,
  Child,
  ListEl = 'div',
  context,
  className,
  onScrollEnd,
  endThreshold = 1,
  overScan = 1,
  style
}: IVirtualListProps<T, C>): ReactElement => {
  const toPx = useToPx();
  gap = toPx(gap);
  height = toPx(height);
  const totalHeight = items.length * height + (items.length - 1) * gap;
  const ref = useRef<HTMLDivElement | null>(null);
  const [window, setWindow] = useState({top: 0, bottom: 0});
  const isEmpty = items.length === 0;
  useEffect(() => {
    if (isEmpty) {
      return undefined;
    }
    const el = ref.current;
    if (!el) {
      return undefined;
    }
    let rafId = 0;
    const updateWindow = () => {
      if (rafId !== 0) {
        cancelAnimationFrame(rafId);
      }
      rafId = requestAnimationFrame(() => {
        rafId = 0;
        let {scrollTop: top, clientHeight} = el;
        top = Math.max(top, 0);
        const bottom = top + clientHeight;
        setWindow({top, bottom});
      });
    };
    el.addEventListener('scroll', updateWindow, {passive: true});
    const resizeOb = new ResizeObserver(updateWindow);
    resizeOb.observe(el);
    return () => {
      resizeOb.unobserve(el);
      resizeOb.disconnect();
      // @ts-ignore
      el.removeEventListener('scroll', updateWindow, {passive: true});
    };
  }, [isEmpty]);
  const startIndex = Math.max(0, Math.floor(window.top / (height + gap)) - overScan);
  const endIndex = Math.min(items.length, Math.ceil(window.bottom / (height + gap)) + overScan);
  // onEnd
  const windowSizeRef = useAsRef(endIndex - startIndex + 1);
  const onScrollEndRef = useAsRef(onScrollEnd);
  const numberOfEntries = items.length;
  useEffect(() => {
    const {current: onScrollEnd} = onScrollEndRef;
    if (typeof onScrollEnd === 'function' && numberOfEntries > 0 && numberOfEntries - endIndex <= endThreshold) {
      onScrollEnd(windowSizeRef.current);
    }
  }, [windowSizeRef, endThreshold, onScrollEndRef, endIndex, numberOfEntries]);
  const mapItem = useCallback(
    (item: T, index: number) => {
      return (
        <Child
          key={item[keyProp] as string}
          item={item}
          top={(startIndex + index) * (height + gap)}
          height={height}
          index={index}
          context={context}
        />
      );
    },
    [context, gap, keyProp, height, startIndex, Child]
  );
  return (
    <div ref={ref} className={className} style={{overflow: 'auto', width: '100%', ...style}}>
      <ListEl style={{height: totalHeight, position: 'relative'}}>
        {items.slice(startIndex, endIndex).map(mapItem)}
      </ListEl>
    </div>
  );
};

const useAsRef = <T,>(value: T) => {
  const ref = useRef(value);
  ref.current = value;
  return ref;
};
