/** @jsx jsx */
import {jsx, css} from '@emotion/react';
import {SC} from '../../../models/util';
import {IAssignmentEvent} from '@netvision/lib-api-gateway';
import {useEventTypes} from '../../../providers/EventTypesProvider';

export const Icon: SC<{event: IAssignmentEvent; size: number}> = ({event, className, size = 20}) => {
  const remSize = `calc(${size}rem / var(--bfs))`;
  const eventTypes = useEventTypes();
  const eventTypeData = eventTypes.get(event.eventType);
  return (
    <div className={className} css={iconCss} style={{height: remSize, width: remSize, fontSize: remSize}}>
      <span className={eventTypeData?.icon?.iconClass || undefined} style={{color: eventTypeData?.icon?.color}} />
    </div>
  );
};

// language=SCSS
const iconCss = css`
  & {
    position: relative;
    height: calc(20rem / var(--bfs));
    width: calc(20rem / var(--bfs));
    font-size: calc(20rem / var(--bfs));
    color: var(--text-color-secondary);
  }
`;
