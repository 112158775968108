import React, {createContext, FC, useContext, useEffect, useState} from 'react';
import {getSharedNotificationSocket} from '@netvision/lib-api-gateway';

const ctx = createContext<ReturnType<typeof getSharedNotificationSocket>>(null!);

export const useSocket = () => {
  return useContext(ctx);
};

export const SocketProvider: FC = ({children}) => {
  const [socket] = useState(getSharedNotificationSocket);
  return <ctx.Provider value={socket}>{children}</ctx.Provider>;
};
