/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import {SC} from '../../models/util';

export const Title: SC = ({children, className, style}) => {
  return (
    <h1 className={className} style={style} css={$title}>
      {children}
    </h1>
  );
};

// language=SCSS
const $title = css`
  & {
    margin: 0;
    font-weight: 500;
    font-size: var(--font-size-h1);
    color: var(--text-color);
  }
`;
